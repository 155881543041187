import { handlePostApiCall, handleGetApiCall } from "../../utils/api.call";
import ProfileImgPreview from "../../public/images/icons/profile_img.svg";
import { getCookie } from "../../utils/cookie";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export const PROFILE_COOKIES = {
  PROFILE_ID: "sub",
  INTERNAL_USER: "intl",
  IS_LOGGED_IN: "li",
};

export const getDefaultProfileObject = () => {
  const profile = {
    id: "",
    firstName: null,
    lastName: null,
    maskedEmail: null,
    internalUser: false,
    isHost: false,
    host: null,
    hostPhotoURL: "",
    instaHandleExists: false,
    instagramHandle: null,
    isEmailVerified: false,
    displayName: null,
    isAdmin: false,
    maskedPhoneNumber: null,
    maskedId: null,
    isCohykAgent: false,
  };
  return profile;
};

export const parseProfileObject = (response) => {
  const profile = getDefaultProfileObject();
  profile.id = response.id ?? profile.id;
  profile.firstName = response.first_name ?? profile.firstName;
  profile.lastName = response.last_name ?? profile.lastName;
  profile.maskedEmail = response.masked_email ?? profile.maskedEmail;
  profile.internalUser = response.is_internal_user ?? profile.internalUser;
  profile.isHost = response.is_host ?? profile.isHost;
  profile.host = response.host ?? profile.host;
  profile.hostPhotoURL =
    response.host?.profile_photo?.cdn_url ?? ProfileImgPreview;
  profile.instaHandleExists =
    response.insta_handle_exists ?? profile.instaHandleExists;
  profile.instagramHandle =
    response.instagram_handle ?? profile.instagramHandle;
  profile.isEmailVerified =
    response.is_email_verified ?? profile.isEmailVerified;
  profile.maskedPhoneNumber =
    response.masked_phone_number ?? profile.maskedPhoneNumber;
  profile.maskedId = response.masked_id ?? profile.maskedId;

  profile.displayName =
    response.host?.first_name ||
    response.host?.instagram_handle ||
    response.first_name ||
    response.instagram_handle ||
    response.masked_id;

  profile.isAdmin = response.is_admin ?? profile.isAdmin;
  profile.isCohykAgent = response.is_cohyk_agent ?? profile.isCohykAgent;

  return profile;
};

export const getProfileFromAPI = async () => {
  const profileResponse =
    (await handleGetApiCall(
      "profile/me",
      {},
      (res) => res.data,
      (res) => res.data
    )) || {};

  const profile = parseProfileObject(profileResponse);
  return profile;
};

export const checkIfInternalUser = () => {
  const cookieVal = getCookie(PROFILE_COOKIES.INTERNAL_USER);
  return parseInt(cookieVal);
};

export const logoutUser = async () => {
  const logoutResponse =
    (await handlePostApiCall({
      url: "auth/logout",
      params: {},
      handleSuccessFn: (res) => res.data,
    })) || {};

  return logoutResponse;
};

/**
 * @returns {[boolean, boolean]} `[isInternalUser, isProfileLoading]`
 */
export function useCheckIfInternalUser() {
  const [isInternalUser, setIsInternalUser] = useState(
    typeof window !== "undefined" ? checkIfInternalUser() : false
  );
  const isProfileLoading = useSelector(
    (state) => state.AuthReducer.isProfileLoading
  );
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);

  useEffect(() => {
    setIsInternalUser(checkIfInternalUser());
  }, [isLoggedIn]);

  return [isInternalUser, isProfileLoading];
}
