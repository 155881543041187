import Trip, { parseTripObject } from "../trip/trip";
import { increaseNewline } from "../../utils/string";
import { handleGetApiCall } from "../../utils/api.call";

export function getDefaultLocationObject() {
  const location = {
    id: 0,
    latitude: 0.0,
    longitude: 0.0,
    altitude: 0,
    name: "",
    region: "",
    description: "",
    geography: "",
    photos: [],
    timeToVisit: "",
    activities: null,
    features: [],
    regionType: null,
    regionTypeName: "",
    externalLink: "",
    weather: null,
    urlSlug: "",
    urlPath: "",
    upcomingTrips: [],
    coverImage: "",
    filterOptionName: "",
    locationType: null,
    tripsUrlPath: "",
  };

  return location;
}

export function parseLocationObject(response) {
  const location = getDefaultLocationObject();

  location.id = response?.id ?? location.id;
  location.latitude = response?.latitude ?? location.latitude;
  location.longitude = response?.longitude ?? location.longitude;
  location.altitude = response?.altitude ?? location.altitude;
  location.name = response?.name ?? location.name;
  location.region = response?.region ?? location.region;
  location.description = response?.detail
    ? increaseNewline(response.detail)
    : location.description;
  location.geography = response?.geography ?? location.geography;
  location.photos = response?.photos ?? location.photos;
  location.timeToVisit = response?.best_time ?? location.timeToVisit;
  location.activities = response?.activities ?? location.activities;
  location.features = response?.feature_details ?? location.features;
  location.regionType = response?.region_type ?? location.regionType;
  location.regionTypeName =
    response?.region_type?.name ?? location.regionTypeName;
  location.externalLink = response?.externalLink ?? location.externalLink;
  location.weather = response?.weather ?? location.weather;
  location.urlSlug = response?.url_slug ?? location.urlSlug;
  location.urlPath = response?.url_path ?? location.urlPath;
  location.locationType = response?.location_type ?? location.locationType;

  location.upcomingTrips = response?.upcoming_trips
    ? response?.upcoming_trips?.map((trip) => parseTripObject(trip))
    : location.upcomingTrips;

  if (response?.photos?.length > 0) {
    location.coverImage = response?.photos[0].cdn_url;
  } else {
    location.coverImage =
      response?.region_type?.bg_img_cdn_url ?? location.coverImage;
  }

  location.filterOptionName = response?.region
    ? `${response?.name}, ${response?.region}`
    : response?.name;

  if (location.locationType?.slug === "collection") {
    const defaultObj = getDefaultLocationObject();
    location.latitude = defaultObj.latitude;
    location.longitude = defaultObj.longitude;
    location.altitude = defaultObj.altitude;
    location.geography = defaultObj.geography;
    location.features = defaultObj.features;
    location.regionType = defaultObj.regionType;
    location.regionTypeName = defaultObj.regionTypeName;
    location.locationType = defaultObj.locationType;
    location.weather = defaultObj.weather;
  }

  location.tripsUrlPath = response?.trips_url_path
    ? response?.trips_url_path.charAt(0) !== "/"
      ? `/${response.trips_url_path}`
      : response.trips_url_path
    : location.tripsUrlPath;

  return location;
}

export default class Location {
  constructor(locationDetail) {
    this.id = locationDetail.id;
    this.latitude = locationDetail.latitude ?? 0.0;
    this.longitude = locationDetail.longitude ?? 0.0;
    this.altitude = locationDetail.altitude ?? 0;
    this.name = locationDetail.name;
    this.region = locationDetail.region;
    this.description = locationDetail.detail
      ? increaseNewline(locationDetail.detail)
      : "";
    this.geography = locationDetail.geography ?? "";
    this.photos = locationDetail.photos ?? [];
    this.timeToVisit = locationDetail.best_time ?? "";
    this.activities = locationDetail.activities;
    this.features = locationDetail.feature_details ?? [];
    this.regionType = locationDetail.region_type ?? {};
    this.regionTypeName = locationDetail.region_type?.name;
    this.externalLink = locationDetail.externalLink ?? "";
    this.weather = locationDetail.weather ?? {};
    this.urlSlug = locationDetail.url_slug ?? "";
    this.urlPath = locationDetail.url_path;

    this.upcomingTrips = [];
    locationDetail.upcoming_trips?.map((tripObject) => {
      this.upcomingTrips.push(new Trip(tripObject));
    });

    if (this.photos.length > 0) {
      this.coverImage = this.photos[0].cdn_url;
    } else {
      this.coverImage = this.regionType.bg_img_cdn_url;
    }

    this.filterOptionName = locationDetail.region
      ? `${locationDetail.name}, ${locationDetail.region}`
      : locationDetail.name;
    this.tripsUrlPath = locationDetail?.trips_url_path
      ? locationDetail?.trips_url_path.charAt(0) !== "/"
        ? `/${locationDetail.trips_url_path}`
        : locationDetail.trips_url_path
      : "";
  }
}

export function getLocationFromId(locationId) {
  const handleSuccessFn = (res) => parseLocationObject(res.data);
  return handleGetApiCall(
    `location/${locationId}`,
    {},
    handleSuccessFn,
    () => {}
  );
}

export function getLocationFromSlug(locationSlug) {
  const handleSuccessFn = (res) => parseLocationObject(res.data);
  return handleGetApiCall(
    `location/slug/${locationSlug}`,
    {},
    handleSuccessFn,
    () => {}
  );
}
