import { getDaysInMonth, getDaysInYear } from "date-fns";

function getCurrentDate() {
  let date = new Date();
  return getDateInHyphenSeparatorFormat(date);
}

function getDateInSlashSeparatorFormat(date) {
  const new__date = new Date(date);
  let day = String(new__date.getDate()).padStart(2, "0");
  let month = String(new__date.getMonth() + 1).padStart(2, "0");
  let year = String(new__date.getFullYear());
  return day + "/" + month + "/" + year;
}

function getDateInHyphenSeparatorFormat(date) {
  const new__date = new Date(date);
  let dd = String(new__date.getDate()).padStart(2, "0");
  let mm = String(new__date.getMonth() + 1).padStart(2, "0");
  let yyyy = String(new__date.getFullYear());
  return yyyy + "-" + mm + "-" + dd;
}

function getReadableDate(date) {
  const new__date = new Date(date);
  const day = new__date.getDate();
  let month = new__date.getMonth();
  let month_str = Month(month + 1);
  return day + " " + month_str;
}

function getMonthRangeShorthand(range) {
  const rangeArray = range.split(" ");
  if (rangeArray.length === 1) return monthShortHand(range);
  const startMonth = rangeArray[0];
  const endMonth = rangeArray[rangeArray.length - 1];
  return `${monthShortHand(startMonth)} - ${monthShortHand(endMonth)}`;
}

function monthShortHand(month) {
  switch (month) {
    case "January":
      return "Jan";
    case "February":
      return "Feb";
    case "March":
      return "Mar";
    case "April":
      return "Apr";
    case "May":
      return "May";
    case "June":
      return "Jun";
    case "July":
      return "Jul";
    case "August":
      return "Aug";
    case "September":
      return "Sep";
    case "October":
      return "Oct";
    case "November":
      return "Nov";
    case "December":
      return "Dec";
    default:
      return "";
  }
}

function Month(month) {
  month = Number(month);
  if (isNaN(month)) return "";
  switch (month) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    default:
      return "";
  }
}

// Parameter can be in the format of dd/mm/yyyy, or dd-mm-yyyy, or any other as long as,
// the splitting condition is provided as the second param
function getDateAndMonth(date, splitBy = "/", reverse = false) {
  let splitDate = date.split(splitBy);
  if (reverse) splitDate = splitDate.reverse();

  const newDate = new Date(date);
  const month = newDate.toLocaleString("default", { month: "short" });

  if (reverse) {
    // Remove prefixed 0
    splitDate[0] = removePrefixZero(splitDate[0]);
    return `${returnDateAsOrdinal(splitDate[0])} ${month.substring(0, 3)}`;
  }

  splitDate[2] = removePrefixZero(splitDate[2]);
  // Need to substring or else app hydration will fail for the month of September
  // Nodejs will return Sept, while the browser window will return Sep, I love JS :)
  return `${returnDateAsOrdinal(splitDate[2])} ${month.substring(0, 3)}`;
}

function dateMonthAndWeekDay(date, splitBy = "/", reverse = false) {
  const newDate = new Date(date);
  const dateAndMonth = getDateAndMonth(date, splitBy, reverse);
  const weekDay = newDate.toLocaleString("default", { weekday: "short" });

  return `${dateAndMonth}, ${weekDay}`;
}

function dateMonthAndYear(date, splitBy = "/", reverse = false) {
  const newDate = new Date(date);
  const dateAndMonth = getDateAndMonth(date, splitBy, reverse);
  const year = newDate.toLocaleString("default", { year: "2-digit" });

  return `${dateAndMonth} '${year}`;
}

function getMonthYear(date) {
  const newDate = new Date(date);
  const month = newDate.toLocaleString("default", { month: "long" });
  const year = newDate.toLocaleString("default", { year: "numeric" });
  return month + ", " + year;
}

function getDay(date) {
  const newDate = new Date(date);
  const weekDay = newDate
    .toLocaleString("default", { weekday: "short" })
    .toLowerCase();
  return weekDay;
}

function isWeekend(date) {
  const newDate = new Date(date);
  const weekDay = newDate
    .toLocaleString("default", { weekday: "short" })
    .toLowerCase();

  if (weekDay == "fri" || weekDay == "sat" || weekDay == "sun") {
    return true;
  }
  return false;
}

function returnDateAsOrdinal(date) {
  if (isNaN(Number(date))) return date;

  if (date >= "10" && date <= "19") {
    return `${date}th`;
  }

  if (date === "1" || date[1] === "1") return `${date}st`;
  else if (date === "2" || date[1] === "2") return `${date}nd`;
  else if (date === "3" || date[1] === "3") return `${date}rd`;

  return `${date}th`;
}

function removePrefixZero(date) {
  if (date.charAt(0) === "0" && date.charAt(1) !== "") return date.charAt(1);

  return date;
}

function getMeridiemTime(time) {
  return new Date(time).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

function getIsoString(time, preserveUtcTz = true, stripMilliseconds = false) {
  let dateIsoString = new Date(time).toISOString();
  if (!preserveUtcTz) dateIsoString = dateIsoString.replace("Z", "");
  if (stripMilliseconds) dateIsoString = dateIsoString.split(".")[0];

  return dateIsoString;
}

/**
 * @returns {string} Date formatted as: `01st Jan '23`
 */
function getOrdinalDateMonthYear(date, hideYearIfCurrent = false) {
  const newDate = new Date(date);
  const dateAndMonth = getDateAndMonth(
    `${newDate.getFullYear()}/${newDate.getMonth() + 1}/${newDate.getDate()}`
  );

  if (hideYearIfCurrent && newDate.getFullYear() === new Date().getFullYear()) {
    return dateAndMonth;
  }

  const year = newDate.toLocaleString("default", { year: "2-digit" });

  return `${dateAndMonth} '${year}`;
}

const MINUTE_SECS = 60;
const HOUR_SECS = MINUTE_SECS * 60;
const DAY_SECS = HOUR_SECS * 24;
const WEEK = DAY_SECS * 7;
const MONTH_SECS = DAY_SECS * getDaysInMonth(new Date());
const YEAR_SECS = DAY_SECS * getDaysInYear(new Date());

function fromNow(date) {
  const secondsAgo = Math.round((Date.now() - new Date(date)) / 1000);

  if (secondsAgo < MINUTE_SECS) {
    return "Just Now";
  }

  let divisor;
  let unit = "";

  if (secondsAgo < HOUR_SECS) {
    divisor = MINUTE_SECS;
    unit = "minute";
  } else if (secondsAgo < DAY_SECS) {
    divisor = HOUR_SECS;
    unit = "hour";
  } else if (secondsAgo < WEEK) {
    divisor = DAY_SECS;
    unit = "day";
  } else if (secondsAgo < MONTH_SECS) {
    divisor = WEEK;
    unit = "week";
  } else if (secondsAgo < YEAR_SECS) {
    divisor = MONTH_SECS;
    unit = "month";
  } else {
    divisor = YEAR_SECS;
    unit = "year";
  }

  const count = Math.floor(secondsAgo / divisor);
  return `${count} ${unit}${count > 1 ? "s" : ""} ago`;
}

export {
  getDay,
  getMonthYear,
  getReadableDate,
  getCurrentDate,
  getDateInHyphenSeparatorFormat,
  getDateInSlashSeparatorFormat,
  getMonthRangeShorthand,
  dateMonthAndWeekDay,
  dateMonthAndYear,
  returnDateAsOrdinal,
  removePrefixZero,
  isWeekend,
  getMeridiemTime,
  getIsoString,
  getOrdinalDateMonthYear,
  fromNow,
  getDateAndMonth,
};
