export default class TripType {
  constructor({
    id,
    name,
    emoji,
    slug,
    description,
    url,
    img_cdn_url,
    trips_url_path,
  }) {
    this.id = id;
    this.name = name;
    this.emoji = emoji;
    this.description = description;
    this.slug = slug;
    this.url = url;
    this.imageUrl = img_cdn_url;
    this.tripsUrlPath = trips_url_path
      ? trips_url_path?.charAt(0) !== "/"
        ? `/${trips_url_path}`
        : trips_url_path
      : "";
  }
}

export function getDefaultTripTypeObj() {
  const tripType = {
    id: 0,
    name: "",
    emoji: "",
    description: "",
    slug: "",
    url: "",
    imageUrl: "",
    tripsUrlPath: "",
  };

  return tripType;
}

export function parseTripTypeObj(response) {
  const tripType = getDefaultTripTypeObj();

  tripType.id = response?.id ?? tripType.id;
  tripType.name = response?.name ?? tripType.name;
  tripType.emoji = response?.emoji ?? tripType.emoji;
  tripType.description = response?.description ?? tripType.description;
  tripType.slug = response?.slug ?? tripType.slug;
  tripType.url = response?.url ?? tripType.url;
  tripType.imageUrl = response?.img_cdn_url ?? tripType.imageUrl;
  tripType.tripsUrlPath = response?.trips_url_path
    ? response?.trips_url_path.charAt(0) !== "/"
      ? `/${response?.trips_url_path}`
      : response?.trips_url_path
    : tripType.tripsUrlPath;

  return tripType;
}
