const initialState = {
  isFeedbackDialogModalOpen: false,
};

const FeedbackReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case "OPEN_FEEDBACK_MODAL":
      return {
        ...state,
        isFeedbackDialogModalOpen: true,
      };

    case "CLOSE_FEEDBACK_MODAL":
      return {
        ...state,
        isFeedbackDialogModalOpen: initialState.isFeedbackDialogModalOpen,
      };

    default:
      return state;
  }
};

export default FeedbackReducer;
