function increaseNewline(str = "") {
  return str.replaceAll("\n", "\n\n");
}

function getCurrencySymbol(symbol) {
  switch (symbol) {
    case "INR":
      return "₹";
    case "USD":
      return "$";
    default:
      return "";
  }
}

function getNumberInCommaFormat(number) {
  return Number(number).toLocaleString();
}

function getNumberInModernCountFormat(number) {
  const div1M = number / 1000000;
  if (div1M >= 1 && div1M < 1000) {
    return parseInt(div1M) + "M";
  }

  const div1K = number / 1000;
  if (div1K >= 1 && div1K < 1000) {
    return parseInt(div1K) + "K";
  }

  const div = number / 100;
  if (div >= 1 && div < 1000) {
    return parseInt(div) + "00";
  }

  return number;
}

function getDurationDetail(duration_in_days) {
  if (duration_in_days > 1) {
    return (
      duration_in_days +
      " days & " +
      (duration_in_days - 1) +
      (duration_in_days - 1 > 1 ? " nights" : " night")
    );
  } else {
    return "1 day";
  }
}

function checkIfValidInstaHandle(value) {
  const instaHandleRegex = new RegExp(/[a-zA-Z0-9._]+/gm);
  const matches = value.match(instaHandleRegex);
  if (matches && matches.length == 1 && matches[0] == value) return true;
  return false;
}

function returnValIfIntOrNull(value) {
  if (isNaN(value)) return null;
  return value;
}

function collapseSpaces(str = "") {
  const spacesRE = RegExp("\\s+", "g");
  return str.replace(spacesRE, " ");
}

function prependLinkProtocol(link = "", protocol = "https://") {
  let parsedLink = link;
  if (!link.includes(protocol, 0)) {
    parsedLink = `${protocol}${link}`;
  }

  return parsedLink;
}

function makeTitleCase(str = "") {
  const wordsArr = collapseSpaces(str).split(" ");

  const titleCaseWordsArr = wordsArr.map(
    (word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`
  );

  return titleCaseWordsArr.join(" ");
}

function createQueryString(queries = {}, useTruthyValues = false) {
  const queryStringArr = [];
  const keys = Object.keys(queries);

  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    const v = queries[k];
    if (!queries.hasOwnProperty(k)) continue;

    if (useTruthyValues && !v) continue;
    else if (v === undefined || v === null) continue;

    queryStringArr.push(`${k}=${v}`);
  }

  if (queryStringArr.length > 0) return queryStringArr.join("&");
  return "";
}

function createURL(baseURL, queryString = "") {
  if (queryString.length <= 0) {
    return baseURL;
  }

  if (baseURL.includes("?")) {
    return `${baseURL}&${queryString}`;
  }

  return `${baseURL}?${queryString}`;
}

function removeSuffix(str = "", suffix = "") {
  const idx = str.lastIndexOf(suffix);
  return str.substring(0, idx === -1 ? str.length : idx);
}

function basicEmailValidation(email = "") {
  const emailRegex = new RegExp(/^.{1,}@.{1,}\..{1,}$/gm);

  return emailRegex.test(email);
}

export {
  increaseNewline,
  getCurrencySymbol,
  getNumberInCommaFormat,
  getNumberInModernCountFormat,
  getDurationDetail,
  checkIfValidInstaHandle,
  returnValIfIntOrNull,
  collapseSpaces,
  prependLinkProtocol,
  makeTitleCase,
  createQueryString,
  createURL,
  removeSuffix,
  basicEmailValidation,
};
