import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#26A77C",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#fff",
    },
    "brand-300": {
      main: "#E5FCF4",
      contrastText: "#fff",
    },
    "brand-400": {
      main: "#5ED2AC",
      contrastText: "#fff",
    },
    "brand-500": {
      main: "#40BA91",
      contrastText: "#fff",
    },
    "brand-600": {
      main: "#26A77C",
      contrastText: "#fff",
    },
    "neutral-900": {
      main: "#0F172A",
      contrastText: "#fff",
    },
    "neutral-800": {
      main: "#1E293B",
      contrastText: "#fff",
    },
    "neutral-700": {
      main: "#334155",
      contrastText: "#fff",
    },
    "neutral-600": {
      main: "#475569",
      contrastText: "#fff",
    },
    "neutral-500": {
      main: "#64748B",
      contrastText: "#F8FAFC",
    },
    "neutral-400": {
      main: "#94A3B8",
      contrastText: "#fff",
    },
    "neutral-300": {
      main: "#CBD5E1",
      contrastText: "#fff",
    },
    "neutral-200": {
      main: "#E2E8F0",
      contrastText: "#475569",
    },
    "neutral-100": {
      main: "#F1F5F9",
      contrastText: "#1E293B",
    },
    "neutral-50": {
      main: "#F8FAFC",
      contrastText: "#fff",
    },
    "rose-600": {
      main: "#E11D48",
      contrastText: "#fff",
    },
    "rose-400": {
      main: "#FB7185",
      contrastText: "#fff",
    },
    "rose-50": {
      main: "#FB7185",
      contrastText: "#fff",
    },
    "amber-600": {
      main: "#D97706",
      contrastText: "#fff",
    },
    "amber-400": {
      main: "#F59E0B",
      contrastText: "#fff",
    },
    "amber-100": {
      main: "#FEF3C7",
      contrastText: "#fff",
    },
    "green-600": {
      main: "#418F6E",
      contrastText: "#fff",
    },
    "green-400": {
      main: "#39D895",
      contrastText: "#fff",
    },
    "green-50": {
      main: "#B0F4D8",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: '"Lexend", sans-serif',
    "text-16xl-bold": {
      fontSize: 120,
      fontWeight: 700,
      lineHeight: 1.33
    },
    "text-h2": {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.4
    },
    "text-8xl-semibold": {
      fontSize: 60,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    "text-6xl-bold": {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: 1.33,
    },
    "text-6xl-semibold": {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    "text-5xl": {
      fontSize: 40,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    "text-4xl-semibold": {
      fontSize: 36,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    "text-3xl-semibold": {
      fontSize: 30,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    "text-2xl-semibold": {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    "text-2xl": {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    "text-xl-semibold": {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    "text-xl": {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    "text-base-slim": {
      fontSize: 16,
      fontWeight: 300,
      lineHeight: 1.5,
    },
    "text-base": {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    "text-base-semibold": {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    "text-sm": {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.43,
    },
    "text-sm-semibold": {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.43,
    },
    "text-xs-slim": {
      fontSize: 12,
      fontWeight: 300,
      lineHeight: 1.33,
    },
    "text-xs": {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    "text-xs-semibold": {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.33,
    },
    "text-xxs": {
      fontSize: 9,
      fontWeight: 400,
      lineHeight: 1.1
    }
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        root: {
          padding: "3px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Lexend", sans-serif',
          fontSize: "inherit",
          color: "inherit",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Lexend", sans-serif',
          lineHeight: "1.32",
          fontWeight: "300",
        },
      },
      variants: [
        {
          props: { variant: "text-16xl-bold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "120px",
            fontWeight: "700",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-h2" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            color: "black",
            fontWeight: "600",
            fontSize: "24px",
          },
        },
        {
          props: { variant: "text-8xl-semibold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "60px",
            fontWeight: "600",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-6xl-bold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "48px",
            fontWeight: "700",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-5xl" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "40px",
            fontWeight: "400",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-6xl-semibold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "48px",
            fontWeight: "600",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-4xl-semibold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "36px",
            fontWeight: "600",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-3xl-semibold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "30px",
            fontWeight: "600",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-2xl-semibold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-2xl" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "24px",
            fontWeight: "400",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-xl-semibold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "1.4",
          },
        },
        {
          props: { variant: "text-xl" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "1.4",
          },
        },
        {
          props: { variant: "text-base-slim" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "16px",
            fontWeight: "300",
            lineHeight: "1.5",
          },
        },
        {
          props: { variant: "text-base" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "1.5",
          },
        },
        {
          props: { variant: "text-base-semibold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "1.5",
          },
        },
        {
          props: { variant: "text-xs-semibold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-xs" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-xs-slim" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "12px",
            fontWeight: "300",
            lineHeight: "1.33",
          },
        },
        {
          props: { variant: "text-sm" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "1.43",
          },
        },
        {
          props: { variant: "text-sm-semibold" },
          style: {
            fontFamily: '"Lexend", sans-serif',
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "1.43",
          },
        },
        {
          props: { variant: "h3" },
          style: {
            fontFamily: '"Lexend", "Lato", sans-serif',
            color: "black",
            fontWeight: "bold",
            fontSize: "22px",
            textAlign: "center",
          },
        },
        {
          props: { variant: "text-xxs" },
          style: {
            fontFamily: '"Lexend", "Lato", sans-serif',
            color: "black",
            fontWeight: "400",
            fontSize: "9px",
          },
        },
      ],
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media(min-width:1200px)': {
            maxWidth: '1440px'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "24px",
          padding: "6px 12px",
          fontFamily: "'Lexend', sans-serif",
          fontSize: "12px",
          fontWeight: "600",
          lineHeight: "1.33",
          contrastText: "#000000",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "16px",
          width: "90%",
          margin: "0 0",
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        input: {
          padding: "8px 14px",
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        input: {
          padding: "8px 14px",
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "8px 14px",
        }
      }
    }
  },
});

export default theme;