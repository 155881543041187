import { getDay, getReadableDate, isWeekend } from "../../utils/date";
import getNumberInModernCountFormat from "../../utils/followers.count";
import {
  increaseNewline,
  getCurrencySymbol,
  getNumberInCommaFormat,
  getDurationDetail,
} from "../../utils/string";
import { getFeaturesForInfoBadge } from "../../utils/features";
import Location, { parseLocationObject } from "../location/location";
import {
  getDefaultWhatsappContactObj,
  parseHostObject,
  parseWhatsappContactObj,
} from "../Host/host";
import { parseReviewsAndRatings } from "../review/review";

function isWeekendTrip(startDate, duration) {
  const weekend = isWeekend(startDate);
  const weekDay = getDay(startDate);
  if (weekend & (duration > 1)) {
    if (
      (weekDay == "thu" && duration <= 4) ||
      (weekDay == "fri" && duration <= 3) ||
      (weekDay == "sat" && duration <= 2)
    ) {
      return true;
    }
  }
  return false;
}

const default_profile_photo_url =
  "https://cohyk.mo.cloudinary.net/op-v1/images/profile-default.jpg";

export function getDefaultTripObj() {
  const trip = {
    id: 0,
    name: "",
    tripType: "",
    emoji: "",
    tripTypeImage: "",
    difficulty: null,
    duration: 0,
    durationStr: "",
    durationDetail: "",
    hostId: 0,
    hostImage: default_profile_photo_url,
    hostName: "",
    hostSearchPhotoURL: default_profile_photo_url,
    hostInstagramHandle: "",
    groupSize: 0,
    costPerPersonInNumber: 0,
    costPerPerson: 0,
    costCurrency: "",
    livesForEmoji: "",
    hostFollowersCount: 0,
    hostURLPath: "/host/",
    urlPath: "",
    hostBio: "",
    hostTripsCount: 0,
    startingLocation: "",
    endingLocation: "",
    highlightLocationId: 0,
    highlightLocation: "",
    highlightLocationDetail: "",
    highlightLocationUrl: "",
    highlightLocationFullDetail: null,
    startDate: "",
    startDate_str: "",
    expectation: "",
    includedCosts: null,
    excludedCosts: null,
    costDetails: "",
    transport: "",
    transports_str: "",
    features: [],
    verifiedHost: false,
    itinerary: null,
    organizerFoodExtra: false,
    coupleFriendly: false,
    kidFriendly: false,
    womenOnly: false,
    highlightLocationCdnUrl: "",
    highlightLocationRegion: "",
    highlightLocationTripsUrlPath: "",
    host: null,
    hashId: "",
    isWeekendTrip: false,

    itineraryId: 0,
    itineraryDetails: null,
    itineraryLocations: [],

    activeDiscounts: [],
    discountValue: null,
    discountPercentage: null,
    discountedCostPerPersonInNumber: 0,
    discountedCostPerPerson: 0,
    discountCode: null,
    disableDiscountCode: false,
    discountPrimaryDescription: "",
    discountSecondaryDescription: "",

    promotedTrip: false,

    hostWhatsappFields: getDefaultWhatsappContactObj(),
    availableSeats: null,
    isBooked: false,
    hostTripsUrlPath: "",
    hostTripCardImg: "",

    cardCoverImageUrl: "",
    reviewsAndRatings: null,
    hostAverageRating: null,
    staticMap: null,
  };

  return trip;
}

export function parseTripObject(response) {
  const trip = getDefaultTripObj();
  trip.id = response?.id ?? trip.id;
  trip.name = response?.highlight_location?.name ?? trip.name;
  trip.tripType = response?.trip_type?.name ?? trip.tripType;
  trip.emoji = response?.trip_type?.emoji ?? trip.emoji;
  trip.tripTypeImage = response?.trip_type?.img_cdn_url ?? trip.tripTypeImage;
  trip.difficulty = response?.difficulty_obj ?? trip.difficulty;
  trip.duration = response?.duration_in_days ?? trip.duration;
  trip.durationStr = parseTripDurationStr(response);
  trip.durationDetail = getDurationDetail(response.duration_in_days);
  trip.hostId = response?.host?.id ?? trip.hostId;
  trip.hostImage = response?.host?.profile_photo?.cdn_url ?? trip.hostImage;
  trip.hostName = [
    response?.host?.first_name ?? "",
    response?.host?.last_name ?? "",
  ]
    .join(" ")
    .trim(" ");
  trip.hostSearchPhotoURL =
    response?.host?.profile_photo_url ?? trip.hostSearchPhotoURL;
  trip.hostInstagramHandle =
    response?.host?.instagram_handle ?? trip.hostInstagramHandle;
  trip.groupSize = response?.group_size ?? trip.groupSize;
  trip.costPerPersonInNumber =
    response?.cost_per_person ?? trip.costPerPersonInNumber;
  trip.costPerPerson = response?.cost_per_person
    ? getNumberInCommaFormat(response.cost_per_person)
    : trip.costPerPerson;
  trip.costCurrency = getCurrencySymbol(response.cost_per_person_currency);
  trip.livesForEmoji = response?.host?.lives_for_emoji ?? trip.livesForEmoji;
  trip.hostFollowersCount = response?.host?.followers_count
    ? getNumberInModernCountFormat(response.host.followers_count)
    : trip.hostFollowersCount;
  trip.hostURLPath = trip.hostURLPath + (response?.host?.handle ?? "");
  trip.urlPath = response?.url_path ?? trip.urlPath;
  trip.hostBio = response?.host?.bio
    ? increaseNewline(response.host.bio)
    : trip.hostBio;
  trip.hostTripsCount = response?.host?.trips_count ?? trip.hostTripsCount;
  trip.startingLocation =
    response?.starting_location?.name ?? trip.startingLocation;
  trip.endingLocation = response?.ending_location?.name ?? trip.endingLocation;

  trip.highlightLocationId =
    response?.highlight_location?.id ?? trip.highlightLocationId;
  trip.highlightLocation =
    response?.highlight_location?.name ?? trip.highlightLocation;
  trip.highlightLocationDetail =
    response?.highlight_location?.short_description ??
    response?.highlight_location?.detail_150 ??
    trip.highlightLocationDetail;
  trip.highlightLocationUrl =
    response?.highlight_location?.url_path ?? trip.highlightLocationUrl;
  trip.highlightLocationFullDetail = response?.highlight_location
    ? parseLocationObject(response.highlight_location)
    : trip.highlightLocationFullDetail;

  trip.startDate = response?.start_date ?? trip.startDate;
  trip.startDate_str = response?.start_date
    ? getReadableDate(response.start_date)
    : trip.startDate_str;
  trip.expectation = response?.expectation
    ? increaseNewline(response.expectation)
    : trip.expectation;
  trip.includedCosts = response?.included_costs
    ? JSON.parse(response.included_costs)
    : trip.includedCosts;
  trip.excludedCosts = response?.excluded_costs
    ? JSON.parse(response.excluded_costs)
    : trip.excludedCosts;
  trip.costDetails = response?.cost_details ?? trip.costDetails;
  trip.transport = response?.transport ?? trip.transport;
  trip.transports_str =
    response?.transports?.map((transport) => transport.name).join(" + ") ??
    trip.transports_str;
  trip.features = response?.feature_details
    ? getFeaturesForInfoBadge(response)
    : trip.features;
  trip.verifiedHost = response?.host?.is_verified ?? trip.verifiedHost;
  trip.itinerary = response?.itinerary_photos ?? trip.itinerary;
  trip.organizerFoodExtra =
    response?.organizer_food_extra ?? trip.organizerFoodExtra;
  trip.coupleFriendly = response?.couple_friendly ?? trip.coupleFriendly;
  trip.kidFriendly = response?.kid_friendly ?? trip.kidFriendly;
  trip.womenOnly = response?.women_only ?? trip.womenOnly;

  trip.highlightLocationCdnUrl =
    response?.highlight_location?.photos?.length > 0
      ? response?.highlight_location?.photos[0].cdn_url
      : response?.highlight_location?.region_type?.bg_img_cdn_url;
  trip.highlightLocationRegion =
    response?.highlight_location?.region ?? trip.highlightLocationRegion;
  trip.highlightLocationTripsUrlPath = response?.highlight_location
    ?.trips_url_path
    ? response?.highlight_location?.trips_url_path.charAt(0) == "/"
      ? `/${response?.highlight_location?.trips_url_path}`
      : response?.highlight_location?.trips_url_path
    : trip.highlightLocationTripsUrlPath;

  trip.host = parseHostObject(response?.host) ?? trip.host;
  trip.hashId = response?.url_path
    ? response?.url_path.split("/")[1]
    : trip.hashId;
  trip.isWeekendTrip = isWeekendTrip(
    response?.startDate ?? "",
    response?.duration_in_days ?? ""
  );

  trip.itineraryId = response?.itinerary_id ?? trip.itineraryId;
  trip.itineraryDetails = response?.itinerary ?? trip.itineraryDetails;
  trip.itineraryLocations = response?.itinerary?.locations
    ? response?.itinerary?.locations?.map((location) =>
        parseLocationObject(location)
      )
    : trip.itineraryLocations;

  trip.activeDiscounts = response?.active_discounts ?? trip.activeDiscounts;
  trip.discountValue = response?.display_discount_value ?? trip.discountValue;
  trip.discountPercentage =
    response?.display_discount_percentage ?? trip.discountPercentage;

  trip.discountedCostPerPersonInNumber = getDiscountedCostPerPerson(
    response.cost_per_person,
    response.display_discount_value,
    response.display_discount_percentage
  );
  trip.discountedCostPerPerson = getNumberInCommaFormat(
    trip.discountedCostPerPersonInNumber
  );
  trip.discountCode = response.discount_code ?? trip.discountCode;
  trip.disableDiscountCode =
    response.discount_details?.disable_discount_code ??
    trip.disableDiscountCode;
  trip.discountPrimaryDescription =
    response.discount_details?.primary_description ??
    trip.discountPrimaryDescription;
  trip.discountSecondaryDescription =
    response.discount_details?.secondary_description ??
    trip.discountSecondaryDescription;

  trip.promotedTrip = response?.promoted_trip ?? trip.promotedTrip;
  trip.availableSeats = response?.available_seats ?? trip.availableSeats;
  trip.isBooked =
    trip.availableSeats !== null && trip.availableSeats === 0
      ? true
      : trip.isBooked;
  trip.hostTripsUrlPath = response?.host?.trips_url_path
    ? response?.host?.trips_url_path.charAt(0) !== "/"
      ? `/${response?.host?.trips_url_path}`
      : response?.host?.trips_url_path
    : trip.hostTripsUrlPath;

  trip.hostTripCardImg =
    response?.host?.profile_photo_url ?? trip.hostTripCardImg;
  trip.cardCoverImageUrl =
    response?.card_cover_image_url ?? trip.cardCoverImageUrl;

  trip.reviewsAndRatings =
    parseReviewsAndRatings(trip.itineraryDetails?.reviews_and_ratings) ??
    trip.reviewsAndRatings;
  trip.hostAverageRating =
    response?.host?.average_rating ?? trip.hostAverageRating;
  trip.staticMap = trip.itineraryDetails?.static_map ?? trip.staticMap;

  return trip;
}

function parseTripDurationStr(tripDetails = {}) {
  let durationStr = "1D";
  if (tripDetails.duration_in_days > 1) {
    durationStr =
      tripDetails.duration_in_days +
      "D & " +
      (tripDetails.duration_in_days - 1) +
      "N";
  }

  return durationStr;
}

function getDiscountedCostPerPerson(
  costPerPerson,
  discountValue,
  discountPercentage
) {
  return costPerPerson - discountValue;
}

export default class Trip {
  constructor(tripDetails = {}) {
    if (tripDetails.duration_in_days > 1) {
      this.durationStr =
        tripDetails.duration_in_days +
        "D & " +
        (tripDetails.duration_in_days - 1) +
        "N";
    } else {
      this.durationStr = "1D";
    }

    const default_profile_photo_url =
      "https://cohyk.mo.cloudinary.net/op-v1/images/profile-default.jpg";

    this.id = tripDetails.id;
    this.name = tripDetails.highlight_location?.name;
    this.tripType = tripDetails.trip_type?.name;
    this.emoji = tripDetails.trip_type?.emoji;
    this.tripTypeImage = tripDetails.trip_type?.img_cdn_url;
    this.difficulty = tripDetails.difficulty_obj;
    this.duration = tripDetails.duration_in_days;
    this.durationStr = this.durationStr;
    this.durationDetail = getDurationDetail(tripDetails.duration_in_days);
    this.hostId = tripDetails.host?.id;
    this.hostImage = tripDetails.host?.profile_photo?.cdn_url
      ? tripDetails.host?.profile_photo?.cdn_url
      : default_profile_photo_url;
    this.hostName = [tripDetails.host?.first_name, tripDetails.host?.last_name]
      .join(" ")
      .trim(" ");
    this.hostSearchPhotoURL = tripDetails.host?.profile_photo_url
      ? tripDetails.host?.profile_photo_url
      : default_profile_photo_url;
    this.hostInstagramHandle = tripDetails.host?.instagram_handle;
    this.groupSize = tripDetails.group_size;
    this.costPerPersonInNumber = tripDetails.cost_per_person;
    this.costPerPerson = getNumberInCommaFormat(tripDetails.cost_per_person);
    this.costCurrency = getCurrencySymbol(tripDetails.cost_per_person_currency);
    this.livesForEmoji = tripDetails.host?.lives_for_emoji;
    this.hostFollowersCount = getNumberInModernCountFormat(
      tripDetails.host?.followers_count
    );
    this.hostURLPath = "/host/" + tripDetails.host.handle;
    this.urlPath = tripDetails.url_path;

    this.hostBio = increaseNewline(tripDetails.host?.bio);
    this.hostTripsCount = tripDetails.host?.trips_count;
    this.startingLocation = tripDetails.starting_location?.name;
    this.endingLocation = tripDetails.ending_location?.name;
    this.highlightLocationId = tripDetails.highlight_location?.id;
    this.highlightLocation = tripDetails.highlight_location?.name;
    this.highlightLocationDetail = tripDetails.highlight_location?.detail_150;
    this.highlightLocationUrl = tripDetails.highlight_location?.url_path;
    this.highlightLocationFullDetail = tripDetails.highlight_location;
    this.startDate = tripDetails.start_date;
    this.startDate_str = getReadableDate(tripDetails.start_date);
    this.expectation = tripDetails.expectation
      ? increaseNewline(tripDetails.expectation)
      : "";
    this.includedCosts = tripDetails.included_costs
      ? JSON.parse(tripDetails.included_costs)
      : null;
    this.excludedCosts = tripDetails.excluded_costs
      ? JSON.parse(tripDetails.excluded_costs)
      : null;
    this.costDetails = tripDetails.cost_details;
    this.transport = tripDetails.transport;
    this.transports_str = tripDetails.transports
      ?.map((transport) => transport.name)
      ?.join(" + ");
    this.features = tripDetails.feature_details
      ? getFeaturesForInfoBadge(tripDetails)
      : [];
    this.verifiedHost = tripDetails.host?.is_verified;
    this.itinerary = tripDetails.itinerary_photos;
    this.organizerFoodExtra = tripDetails.organizer_food_extra;
    this.coupleFriendly = tripDetails.couple_friendly;
    this.kidFriendly = tripDetails.kid_friendly;
    this.womenOnly = tripDetails.women_only;
    // Only for trip/{tripId} details page
    this.highlightLocationCdnUrl =
      tripDetails?.highlight_location?.photos?.length > 0
        ? tripDetails?.highlight_location?.photos[0].cdn_url
        : tripDetails?.highlight_location?.region_type?.bg_img_cdn_url;
    this.highlightLocationRegion = tripDetails?.highlight_location.region;
    this.host = tripDetails.host;
    this.hashId = tripDetails.url_path
      ? tripDetails.url_path.split("/")[1]
      : "";
    this.isWeekendTrip = isWeekendTrip(this.startDate, this.duration);

    this.itineraryId = tripDetails.itinerary_id;
    this.itineraryDetails = tripDetails.itinerary;
    this.itineraryLocations = tripDetails.itinerary?.locations.map(
      (location) => new Location(location)
    );

    this.activeDiscounts = tripDetails.active_discounts;
    this.discountValue = tripDetails.display_discount_value;
    this.discountPercentage = tripDetails.display_discount_percentage;
    this.discountedCostPerPersonInNumber = getDiscountedCostPerPerson(
      tripDetails.cost_per_person,
      tripDetails.display_discount_value,
      tripDetails.display_discount_percentage
    );
    this.discountedCostPerPerson = getNumberInCommaFormat(
      this.discountedCostPerPersonInNumber
    );
    this.discountCode = tripDetails.discount_code;

    this.promotedTrip = tripDetails.promoted_trip ?? false;
  }
}
