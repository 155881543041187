import FilterClass from "../filter.class";
import store from "../../../store";
import { getCurrentDate } from "../../../utils/date";

const isEmptyFn = () => {
  return !store.getState().FilterReducer?.startDate;
};
const resetFn = () => {
  store.dispatch({
    type: "RESET_START_DATE_FILTER",
  });
};

function getReadableDateString(inputDate) {
  let newDate = new Date(inputDate ? inputDate : new Date());
  let [day, month, date] = newDate.toString().split(" ").splice(0, 3);
  if (Number(date) % 10 === 1) {
    date += "st";
  } else if (Number(date) % 10 === 2) {
    date += "nd";
  } else if (Number(date) % 10 === 3) {
    date += " rd";
  } else date += "th";
  return date + " " + month;
}
function getValueStr() {
  return getReadableDateString(this.value());
}

function isValidHighlightStateFn() {
  return true;
  if (this.defaultValue === this.value()) return false;

  return true;
}

const defaultValue = getCurrentDate();

export const StartDateFilterObj = new FilterClass({
  id: "1",
  reduxKey: "startDate",
  name: "start date",
  label: "Starting date(s) to be",
  prefix: "from",
  resetFn,
  isEmptyFn,
  getValueStr,
  defaultValue,
  isValidHighlightStateFn: isValidHighlightStateFn,
});
