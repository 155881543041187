import { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { StartDateFilterObj } from "../filter/date-filter/start.date.filter.obj";
import { FILTER_DATA_SOURCE } from "./common";
import getSearchedTrips from "../calendar/getSearchedTrips.action";
import Router from "next/router";

const searchRoutesRegex = [
  /^\/search\/trips/,
  /^\/host\/[^\/]+\/upcoming-trips/,
  /^\/[a-z]-trips/,
  /^\/location\/[^\/]+\/upcoming-trips/,
];

export default function useSearchUrlManager({
  startDate,
  destinationLocationId,
  destinationDetails,
  // tripTypeArr,
  tripType,
  startingLocationId,
  hostId,
  hostDetails,
  womenOnly,
  noMoreAvailableTrips,
  verifiedHost,
  budget,
  hasDiscount,
  groupSize,
  disableRedirection = true,
  tripsResult,
}) {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (tripsResult) {
      dispatch(getSearchedTrips(tripsResult));
    }
  }, [tripsResult]);

  useEffect(() => {
    const promiseArr = [];
    /*
     * Set values from query params to redux
     */

    dispatch({
      type: "SET_START_DATE_FILTER",
      payload: {
        date: startDate || StartDateFilterObj.defaultValue,
      },
    });

    if (noMoreAvailableTrips) {
      dispatch({ type: "SET_NO_MORE_AVAILABLE_TRIPS" });
    }

    // custom url
    // if (tripType) {
    //   dispatch({
    //     type: "SET_TRIP_TYPE_FILTER",
    //     payload: {
    //       id: tripType.id,
    //       name: tripType.name,
    //       description: tripType.description,
    //       emoji: tripType.emoji,
    //       slug: tripType.slug,
    //       trips_url_path: tripType.trips_url_path,
    //     },
    //   });
    // }

    if (womenOnly) {
      dispatch({
        type: "SET_WOMEN_ONLY_FILTER",
        payload: { womenOnly: true },
      });
    }

    if (verifiedHost) {
      dispatch({
        type: "SET_VERIFIED_HOST_FILTER",
        payload: {
          verifiedHost: true,
        },
      });
    }

    // change url param
    if (budget) {
      dispatch({
        type: "SET_TRIP_BUDGET_FILTER",
        payload: {
          tripBudget: budget,
        },
      });
    }

    if (hasDiscount) {
      dispatch({
        type: "SET_DISCOUNT_FILTER",
        payload: {
          hasDiscount: true,
        },
      });
    }

    // custom url
    if (groupSize?.id) {
      dispatch({
        type: "SET_GROUP_SIZE_FILTER",
        payload: {
          ...groupSize,
        },
      });
    }

    // custom url
    // if (destinationDetails) {
    //   dispatch({
    //     type: "SET_DESTINATION_LOCATION_FILTER",
    //     payload: {
    //       id: destinationDetails.id,
    //       name: destinationDetails.name,
    //       description: destinationDetails.description,
    //       url_path: destinationDetails.urlPath,
    //       url_slug: destinationDetails.urlSlug,
    //       photos: destinationDetails.photos,
    //       region_type: destinationDetails.regionType,
    //       region: destinationDetails.region,
    //       trips_url_path: destinationDetails.tripsUrlPath,
    //     },
    //   });
    // }

    // custom url
    // if (hostDetails) {
    //   dispatch({
    //     type: "SET_HOST_FILTER",
    //     payload: {
    //       id: hostDetails.id,
    //       name: hostDetails.firstName || hostDetails.instagramHandle,
    //       instagram_handle: hostDetails.instagramHandle,
    //       trips_url_path: hostDetails.tripsUrlPath,
    //     },
    //   });
    // }

    // Code is simplied when using Promise.all with await syntax
    // Wrapping inside an async function to use await
    const tempAsync = async () => {
      const [startingLocationDetails] = await Promise.all(promiseArr);

      if (startingLocationDetails) {
        dispatch({
          type: "SET_STARTING_LOCATION_FILTER",
          payload: {
            id: startingLocationDetails.id,
            name: startingLocationDetails.name,
            description: startingLocationDetails.description,
          },
        });
      }
    };
    tempAsync().finally(() => {
      // Indicating through redux across components that the filter values have been loaded
      // and can be used
      dispatch({ type: "SET_FILTERS_LOADED" });
    });
  }, []);

  useEffect(() => {
    if (hostDetails) {
      dispatch({
        type: "SET_HOST_FILTER",
        payload: {
          details: {
            id: hostDetails.id,
            name: hostDetails.firstName || hostDetails.instagramHandle,
            instagram_handle: hostDetails.instagramHandle,
            trips_url_path: hostDetails.tripsUrlPath,
          },
          dataSource: FILTER_DATA_SOURCE.SSR,
        },
      });
    }

    if (tripType) {
      dispatch({
        type: "SET_TRIP_TYPE_FILTER",
        payload: {
          details: {
            id: tripType.id,
            name: tripType.name,
            description: tripType.description,
            emoji: tripType.emoji,
            slug: tripType.slug,
            trips_url_path: tripType.tripsUrlPath,
          },
          dataSource: FILTER_DATA_SOURCE.SSR,
        },
      });
    }

    if (destinationDetails) {
      dispatch({
        type: "SET_DESTINATION_LOCATION_FILTER",
        payload: {
          details: {
            id: destinationDetails.id,
            name: destinationDetails.name,
            description: destinationDetails.description,
            url_path: destinationDetails.urlPath,
            url_slug: destinationDetails.urlSlug,
            photos: destinationDetails.photos,
            region_type: destinationDetails.regionType,
            region: destinationDetails.region,
            trips_url_path: destinationDetails.tripsUrlPath,
          },
          dataSource: FILTER_DATA_SOURCE.SSR,
        },
      });
    }
  }, [hostDetails?.id, tripType?.id, destinationDetails?.id]);

  // Reset search filters/state when url changes to a non-search page
  useEffect(() => {
    function handleStart(url) {
      if (typeof url !== "string") return;
      else if (searchRoutesRegex.some((routeRegex) => routeRegex.test(url)))
        return;

      // To: filters reducer
      dispatch({ type: "RESET_ALL_FILTERS" });
      dispatch({ type: "SET_FILTERS_NOT_LOADED" });
      // To: filterSearchedTrips reducer, reset page count etc
      dispatch({ type: "RESET_SEARCH_PAGINATION" });
    }

    router.events.on("routeChangeStart", handleStart);

    return () => {
      router.events.off("routeChangeStart", handleStart);
    };
  }, [router]);
}

export const searchUrlResetReduxMidware =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (action?.type?.startsWith("RESET_")) {
      let redirectUrl = null;
      const filterState = getState().FilterReducer;

      switch (action?.type) {
        case "RESET_DESTINATION_LOCATION_FILTER":
          redirectUrl =
            filterState?.host?.details?.tripsUrlPath ||
            filterState?.tripType?.details?.tripsUrlPath ||
            "/search/trips";
          break;

        case "RESET_TRIP_TYPE_FILTER":
          redirectUrl =
            filterState?.host?.details?.tripsUrlPath ||
            filterState?.destinationLocation?.details?.tripsUrlPath ||
            "/search/trips";
          break;

        case "RESET_HOST_FILTER":
          redirectUrl =
            filterState?.tripType?.details?.tripsUrlPath ||
            filterState?.destinationLocation?.details?.tripsUrlPath ||
            "/search/trips";
          break;

        default:
          break;
      }

      if (redirectUrl && redirectUrl !== window.location.pathname) {
        Router.replace(redirectUrl, undefined, {
          scroll: false,
        });
      }
    }

    next(action);
  };
