const SEARCH_CONTEXT = {
  HOME_PAGE: "home_page",
  SEARCH: "search_trips",
  LOCATION: "location_trips",
  PROFILE: "profile_trips",
  TRIP_PAGE: "trip_page",
  HOME_PAGE_ALL_TRIPS: "home_page_all_trips",
  HOME_PAGE_LOCATION_TRIPS: "home_page_location_trips",
  HOME_PAGE_WOMEN_ONLY_TRIPS: "home_page_women_only_trips",
  HOME_PAGE_PHOTOGRAPHY_TRIPS: "home_page_photography_trips",
  HOME_PAGE_HIKE_TRIPS: "home_page_hike_trips",
  HOME_PAGE_HOLIDAY_TRIPS: "home_page_holiday_trips",
};

const SEARCH_ENTITY_TYPE = {
  HOST: "host",
  LOCATION: "location",
  TRIP_TYPE: "trip_type",
};

export { SEARCH_CONTEXT, SEARCH_ENTITY_TYPE };
