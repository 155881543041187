import FilterClass from "../filter.class";
import store from "../../../store";
import { getDefaultGroupSizeObj } from "./group.size.obj";

const isEmptyFn = () => {
  return (
    !store.getState().FilterReducer.groupSize?.id ===
    getDefaultGroupSizeObj().id
  );
};
function resetFn() {
  store.dispatch({
    type: "RESET_GROUP_SIZE_FILTER",
  });
}

function getValueStr() {
  return this.value && this.value()?.name;
}

function isValidHighlightStateFn() {
  if (this.defaultValue.id === this.value().id) return false;

  return true;
}

const defaultValue = getDefaultGroupSizeObj();

export const GroupSizeFilterObj = new FilterClass({
  id: "1",
  reduxKey: "groupSize",
  name: "groupSizegroup size",
  label: "Group Size",
  prefix: "",
  resetFn,
  isEmptyFn,
  getValueStr,
  defaultValue,
  isValidHighlightStateFn: isValidHighlightStateFn,
});
