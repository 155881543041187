function getFeaturesForInfoBadge(tripDetails = {}) {
  const featureDetails = tripDetails?.feature_details ?? [];
  const accommodationDetails =
    tripDetails?.itinerary?.accommodation_details ?? [];

  let features = [];

  let mealItr = 0,
    mealFeatureId = "",
    mealFeatureName = "",
    mealDescription = "Meals included in the cost: ";

  featureDetails.map((feature) => {
    if (feature.type == "network") {
      // Replacing the last space with newline for better looking infobox
      const idx = feature.name.lastIndexOf(" ");
      features.push({
        ...feature,
        name: `${feature.name.substring(0, idx)}\n${feature.name.substring(
          idx + 1
        )}`,
      });
    } else if (feature.type == "meal") {
      mealFeatureId = mealItr
        ? mealFeatureId + `_${feature.id}`
        : mealFeatureId + feature.id;
      mealFeatureName = mealItr
        ? mealFeatureName + `${feature.name} `
        : mealFeatureName + `${feature.name}\n`;
      mealDescription = mealItr
        ? mealDescription + `, ${feature.description}`
        : mealDescription + feature.description;
      mealItr++;
    } else if (feature.type == "travel-expense") {
      // Replacing the last space with newline for better looking infobox
      const idx = feature.name.lastIndexOf(" ");
      features.push({
        ...feature,
        name: `${feature.name.substring(0, idx)}\n${feature.name.substring(
          idx + 1
        )}`,
      });
    } else features.push(feature);
  });

  if (!mealItr)
    features.push({
      id: "no_meals",
      name: "No meals",
      description: "No meals included in the cost.",
      type: "meal",
    });
  else
    features.push({
      id: mealFeatureId,
      name: mealFeatureName,
      description: mealDescription,
      type: "meal",
    });

  if (
    tripDetails.trip_type?.name == "Trek/Hike" &&
    tripDetails.distance &&
    tripDetails.distance_unit
  ) {
    features.push({
      id: "trek_distance",
      name: `${tripDetails.distance} ${tripDetails.distance_unit}\nTrek`,
      description: "Distance covered throughout the course of the trek/hike",
      type: "distance",
    });
  }

  if (accommodationDetails?.length === 0) {
    features.push({
      id: "no_stay",
      name: "No stay",
      description: "No stay included in the cost.",
      type: "stay",
    });
  } else {
    accommodationDetails?.forEach((accommodation) => {
      let stayRatingNames = "";
      const stayRatingDescriptionsArr = [];
      accommodation["stay-rating"]?.forEach((rating) => {
        stayRatingNames += `${rating?.name}\n`;
        stayRatingDescriptionsArr.push(rating?.name ?? "");
      });

      features.push({
        id: accommodation?.stay?.id,
        name: `${stayRatingNames}${accommodation?.stay?.name}`,
        description: `Stay at ${stayRatingDescriptionsArr.join(" + ")} ${
          accommodation?.stay?.description
        }`,
        type: "stay",
      });
    });
  }

  return features;
}

export { getFeaturesForInfoBadge };
