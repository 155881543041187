import FilterClass from "../filter.class";
import store from "../../../store";
import Host from "../../Host/host";

const isEmptyFn = () => {
  return !store.getState().FilterReducer?.host?.details?.id;
};
function resetFn() {
  store.dispatch({
    type: "RESET_HOST_FILTER",
  });
}

function getValueStr() {
  return this.value && this.value()?.name;
}

function isValidHighlightStateFn() {
  if (this.defaultValue.id === this.value().id) return false;
  return true;
}

const defaultValue = new Host({ id: "", first_name: "Any", last_name: "" });

export const HostFilterObj = new FilterClass({
  id: "1",
  reduxKey: "host",
  name: "host",
  label: "Host of the trips to be",
  prefix: "by",
  resetFn,
  isEmptyFn,
  getValueStr,
  defaultValue,
  isValidHighlightStateFn: isValidHighlightStateFn,
});
