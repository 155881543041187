import { useState, useEffect } from "react";

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    function resizeListener() {
      if (window.innerWidth < 900) {
        setIsMobile(true);
      } else if (window.innerWidth >= 900) setIsMobile(false);
    }

    // Call it once, for initial page load,
    // if device is larger than 900p, since default is mobile
    resizeListener();

    window.addEventListener("resize", resizeListener);

    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  return isMobile;
}
