const initialState = {
  isOpen: false,
  isOpenForBudget: false,
  isOpenForHost: false,
  isOpenForDestinationLocation: false,
  isOpenForDate: false,
  isOpenForStartLocation: false,
  isOpenForTripType: false,
  isOpenForGroupSize: false,
  handleIsOpenFn: () => {},
};

/*
    DATE : date
    HOST : host
    TRIP_BUDGET : trip budget
    STARTING_LOCATION : starting location
    DESTINATION : destination
*/

const FilterDialogReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case "OPEN_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "CLOSE_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "OPEN_BUDGET_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "CLOSE_BUDGET_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "OPEN_HOST_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "CLOSE_HOST_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "OPEN_DESTINATION_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "CLOSE_DESTINATION_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "OPEN_DATE_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "CLOSE_DATE_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "OPEN_START_LOCATION_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "CLOSE_START_LOCATION_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "OPEN_TRIP_TYPE_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "CLOSE_TRIP_TYPE_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "OPEN_GROUP_SIZE_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    case "CLOSE_GROUP_SIZE_FILTER_DIALOG":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default FilterDialogReducer;
