import { configureStore } from "@reduxjs/toolkit";
import Reducer from "./reducer";
import { searchUrlResetReduxMidware } from "./components/search/search.manager";

const store = configureStore({
  reducer: Reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(searchUrlResetReduxMidware),
});
export default store;
