/**
 * @typedef {Object} GroupSizeObj
 * @property {string} id
 * @property {string} name
 * @property {string} description
 */

/**
 * @returns {GroupSizeObj}
 */
function getEmptyGroupSizeObj() {
  const groupSize = {
    id: "",
    name: "",
    description: "",
  };

  return groupSize;
}

/**
 * @returns {GroupSizeObj}
 */
function parseGroupSizeObj(obj = {}) {
  const groupSize = getEmptyGroupSizeObj();
  groupSize.id = obj?.id ?? groupSize.id;
  groupSize.name = obj?.name ?? groupSize.name;
  groupSize.description = obj?.description ?? groupSize.description;

  return groupSize;
}

/**
 * @returns {GroupSizeObj}
 */
function getGroupSizeObjById(obj = {}) {
  let groupSize = getDefaultGroupSizeObj();
  if (obj?.id === groupSize.id) {
    return groupSize;
  }
  groupSize = getSmallGroupSizeObj();
  if (obj?.id === groupSize.id) {
    return groupSize;
  }
  groupSize = getMediumGroupSize();
  if (obj?.id === groupSize.id) {
    return groupSize;
  }
  groupSize = getLargeGroupSize();
  if (obj?.id === groupSize.id) {
    return groupSize;
  }

  return getEmptyGroupSizeObj();
}

function getDefaultGroupSizeObj() {
  const groupSize = getEmptyGroupSizeObj();
  groupSize.id = "all";
  groupSize.name = "All";
  groupSize.description = "";

  return groupSize;
}

function getSmallGroupSizeObj() {
  const groupSize = getEmptyGroupSizeObj();
  groupSize.id = "small";
  groupSize.name = "Small";
  groupSize.description = "Upto 6 people";

  return groupSize;
}

function getMediumGroupSize() {
  const groupSize = getEmptyGroupSizeObj();
  groupSize.id = "medium";
  groupSize.name = "Medium";
  groupSize.description = "7-14 people";

  return groupSize;
}

function getLargeGroupSize() {
  const groupSize = getEmptyGroupSizeObj();
  groupSize.id = "large";
  groupSize.name = "Large";
  groupSize.description = "Over 14 people";

  return groupSize;
}

export {
  getEmptyGroupSizeObj,
  parseGroupSizeObj,
  getGroupSizeObjById,
  getDefaultGroupSizeObj,
  getSmallGroupSizeObj,
  getMediumGroupSize,
  getLargeGroupSize,
};
