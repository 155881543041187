import * as React from "react";
import Head from "next/head";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from "@emotion/react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";

import theme from "../styles/themes/default";
import createEmotionCache from "../styles/themes/emotion-cache";
import "./../styles/global.css";
import store from "../store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Client-side cache, shared for the whole session of the user in the browser.
// https://blog.sycamore.design/material-ui-next-js-typescript
// https://stackoverflow.com/questions/57918626/have-a-common-header-layout-in-nextjs

const clientSideEmotionCache = createEmotionCache();

export default function App(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  return (
    <Provider store={ store }>
      <CacheProvider value={ emotionCache }>
        <Head>
          <meta key="viewport" name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={ theme }>
          <CssBaseline />
          <Component { ...pageProps } />
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  );
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
