import { parseHostObject, parseTeamMemberObject } from "../Host/host";
import { parseHostAwardObject } from "../Host/host";
import { AUTH_CONTEXT, AUTH_TYPE } from "./login";
import { getDefaultProfileObject } from "./profile";

const defaultProfileObj = getDefaultProfileObject();
const initialState = {
  isHostRegisterModalOpen: false,
  isLoginModalOpen: false,
  isLoggedIn: false,
  isProfileLoading: true,
  profile: defaultProfileObj,
  authType: AUTH_TYPE.LOGIN,
  authContext: AUTH_CONTEXT.DEFAULT,
  // Used for displaying host/booking discount details in auth dialog
  authHostDetails: null,
  authBookingCurrency: null,
  authBookingCost: null,
};

const AuthReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case "OPEN_HOST_REGISTER_MODAL":
      return {
        ...state,
        isHostRegisterModalOpen: true,
      };

    case "OPEN_LOGIN_MODAL":
      const authType = payload.authType ?? AUTH_TYPE.LOGIN;
      const authContext = payload.authContext ?? AUTH_CONTEXT.DEFAULT;
      const hostDetails = payload.hostDetails ?? null;
      const bookingCurrency = payload.bookingCurrency ?? null;
      const bookingCost = payload.bookingCost ?? null;

      return {
        ...state,
        isLoginModalOpen: true,
        authType,
        authContext,
        authHostDetails: hostDetails,
        authBookingCurrency: bookingCurrency,
        authBookingCost: bookingCost,
      };

    case "CLOSE_HOST_REGISTER_MODAL":
      return {
        ...state,
        isHostRegisterModalOpen: initialState.isHostRegisterModalOpen,
      };

    case "CLOSE_LOGIN_MODAL":
      return {
        ...state,
        isLoginModalOpen: initialState.isLoginModalOpen,
        authType: initialState.authType,
        authContext: initialState.authContext,
        authHostDetails: initialState.authHostDetails,
      };

    case "SET_USER_PROFILE":
      if (payload.host) payload.host = parseHostObject(payload.host);

      let isLoggedIn = false;
      if (payload.maskedId) isLoggedIn = true;

      return {
        ...state,
        isLoggedIn,
        profile: payload,
      };

    case "RESET_USER_PROFILE":
      return {
        ...state,
        isLoggedIn: false,
        profile: initialState.profile,
      };

    case "SET_HOST_AWARDS":
      return {
        ...state,
        profile: {
          ...state.profile,
          host: {
            ...state.profile.host,
            awards: payload.awards?.map((award) => parseHostAwardObject(award)),
          },
        },
      };

    case "SET_HOST_TEAM":
      return {
        ...state,
        profile: {
          ...state.profile,
          host: {
            ...state.profile.host,
            team: payload.teamMembers?.map((member) =>
              parseTeamMemberObject(member)
            ),
          },
        },
      };

    case "SET_PROFILE_LOADING":
      return {
        ...state,
        isProfileLoading: true,
      };

    case "SET_PROFILE_LOADED":
      return {
        ...state,
        isProfileLoading: false,
      };

    default:
      return state;
  }
};

export default AuthReducer;
