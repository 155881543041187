import FilterClass from "../filter.class";
import store from "../../../store";
import TripType from "../trip.type.class";

const isEmptyFn = () => {
  return !store.getState().FilterReducer?.tripType?.details?.id;
};
const resetFn = () => {
  store.dispatch({
    type: "RESET_TRIP_TYPE_FILTER",
  });
};
function getValueStr() {
  return this.value && this.value()?.name;
}

function isValidHighlightStateFn() {
  if (this.defaultValue.id === this.value().id) return false;

  return true;
}

const defaultValue = new TripType({ id: "", name: "Any", slug: "any" });

export const TripTypeFilterObj = new FilterClass({
  id: "1",
  reduxKey: "tripType",
  name: "tripTypeObj",
  label: "Looking for a specific activity on the trip?",
  prefix: "for",
  resetFn,
  isEmptyFn,
  getValueStr,
  defaultValue,
  isValidHighlightStateFn,
});
