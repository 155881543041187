import FilterClass from "../filter.class";
import store from "../../../store";
import Location from "../../location/location";

const isEmptyFn = () => {
  return !store.getState().FilterReducer?.highlightLocation?.name;
};
const resetFn = () => {
  store.dispatch({
    type: "RESET_STARTING_LOCATION_FILTER",
  });
};
function getValueStr() {
  return this.value && this.value()?.name;
}

function isValidHighlightStateFn() {
  if (this.defaultValue.id === this.value().id) return false;

  return true;
}
const defaultValue = new Location({ id: "", name: "" });
const defaultFilterValue = new Location({
  id: 6,
  name: "New Delhi",
  region: "India",
});

export const StartingLocationFilterObj = new FilterClass({
  id: "1",
  reduxKey: "startingLocation",
  name: "start location",
  label: "Starting Location",
  prefix: "",
  resetFn,
  isEmptyFn,
  getValueStr,
  defaultValue,
  isValidHighlightStateFn: isValidHighlightStateFn,
  defaultFilterValue,
});
