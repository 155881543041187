import { useState, useEffect, useRef, forwardRef } from "react";
import { useRouter } from "next/router";
import {
  Dialog,
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Slide,
  CircularProgress,
} from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./auth.module.css";
import useIsMobile from "../../utils/device.type.hook";
import { handlePostApiCall } from "../../utils/api.call";
import { checkIfInternalUser, getProfileFromAPI } from "./profile";
import { useCountdown } from "../../utils/countdown";
import { captureEvent, captureGAWEvent } from "../google-analytics/tag";
import { EVENTNAME, SECTION } from "../google-analytics/constants";
import OTPBoxes from "./otp.box";
import { useDispatch } from "react-redux";

const OTP_LENGTH = 4;
const RESEND_OTP_BUFFER_SECONDS = 10;

const mobileDialogStyle = {
  borderRadius: "16px 16px 0 0",
  width: "100%",
  marginTop: "auto",
  minHeight: "300px",
  overscrollBehavior: "contain",
};

const mobileResetDialogStyle = {
  borderRadius: "16px 16px 0 0",
  width: "100%",
  marginTop: "auto",
  minHeight: "380px",
  overscrollBehavior: "contain",
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResendOTP = ({ setErrorMessageFn, phoneNumberOrEmail, context }) => {
  const [apiLoading, setApiLoading] = useState(false);
  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
  const [startTimer, setStartTimer] = useState(false);

  const enableResendButton = () => setResendButtonDisabled(false);
  const bufferTime = useCountdown(
    RESEND_OTP_BUFFER_SECONDS,
    startTimer,
    enableResendButton
  );

  const handleResendOTP = async (e) => {
    e.preventDefault();
    if (resendButtonDisabled) return;

    setResendButtonDisabled(true);
    setApiLoading(true);
    setErrorMessageFn("");

    const handleSuccessFn = (res) => res.data;
    const resendResponse = await handlePostApiCall({
      url: "auth/login",
      params: {
        phone_number_or_email: phoneNumberOrEmail,
      },
      handleSuccessFn,
    });
    setApiLoading(false);

    if (resendResponse.status != "success") {
      setErrorMessageFn(resendResponse.message);
    }
    setStartTimer(true);
  };

  useEffect(() => {
    if (bufferTime == "00:00") setStartTimer(false);
  }, [bufferTime]);

  return (
    <Box display="flex">
      <Typography
        className={styles.resend_otp}
        variant="text-sm"
        color="primary.main"
        onClick={handleResendOTP}
      >
        Resend OTP
      </Typography>
      {apiLoading && (
        <Box className={styles.resend_otp_progress}>
          <CircularProgress size={12} thickness={5} />
        </Box>
      )}
      {!apiLoading && resendButtonDisabled && bufferTime != "00:00" && (
        <Typography
          className={styles.resend_otp_time}
          variant="text-sm"
          color="primary.main"
        >
          in {bufferTime}
        </Typography>
      )}
    </Box>
  );
};

const AuthOTPForm = ({
  isDialogOpen,
  closeDialog,
  crossDialogFn,
  closeAuthDialog,
  context,
  email,
  disableClose = false,
  phoneNumberOrEmail = "",
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isMobile = useIsMobile();
  const [submitButtonDisabled, setButtonDisabled] = useState(false);
  const [userEmail, setUserEmail] = useState(email);
  const [isPasswordVisible, showPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [otp, setOtp] = useState("");

  function handleOtpChange(newOtpVal) {
    setOtp(newOtpVal);
  }

  const handlePasswordChange = (e) => setNewPassword(e.target.value);
  const handleShowPasswordClick = (e) => showPassword(!isPasswordVisible);

  const handleCrossClick = () => {
    closeDialog();
    crossDialogFn();
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    const oneTimePassword = parseInt(otp);
    if (isNaN(oneTimePassword)) {
      setErrorMessage("Please enter a valid OTP.");
      setButtonDisabled(false);
      return;
    }

    if (context == "LOGIN" || context == "REGISTER") {
      const params = {
        otp: oneTimePassword,
        context: context,
        phone_number_or_email: phoneNumberOrEmail,
      };

      const handleSuccessFn = (res) => res.data;
      const verifyResponse = await handlePostApiCall({
        url: "auth/login/verify-otp",
        params,
        handleSuccessFn,
      });
      setButtonDisabled(false);

      if (
        verifyResponse.status == "success" ||
        verifyResponse?.detail?.status === "success"
      ) {
        if (context == "LOGIN") {
          captureEvent(EVENTNAME.LOGIN_OTP_COMPLETE_CLICK, SECTION.LOGIN_FORM);

          if (verifyResponse.context === "signup" && !checkIfInternalUser()) {
            captureEvent(EVENTNAME.SIGNUP_COMPLETE, SECTION.LOGIN_FORM);
            captureGAWEvent(EVENTNAME.SIGNUP_COMPLETE);
          }
        } else {
          captureEvent(
            EVENTNAME.SIGNUP_HOST_OTP_COMPLETE_CLICK,
            SECTION.LOGIN_FORM
          );
        }

        verifyResponse.redirect_to == "/reload"
          ? router.reload()
          : router.push(verifyResponse.redirect_to);

        /*
        After BaseLayout mounts, 
        BaseLayout dispatches isProfileLoading = true -> /profile/me call -> dispatches isProfileLoading = false
        After logging in/registering/signing-up, in the cases where user is redirected
        to /create-host|/dashboard{/analytics|/manage-trips}, since the layouts of both links inherit BaseLayout,
        upon mounting BaseLayout does it's things
        But before BaseLayout dispatches isProfileLoading = true, the consumer's of the profile in Redux, 
        read isProfileLoading as false
        */
        dispatch({ type: "SET_PROFILE_LOADING" });
        closeDialog();
        closeAuthDialog();
      } else
        setErrorMessage(
          verifyResponse.message || verifyResponse?.detail?.message
        );
    } else if (context == "FORGOT") {
      if (!newPassword) {
        setErrorMessage("Please enter a valid password.");
        setButtonDisabled(false);
        return;
      }

      const params = {
        email: userEmail,
        otp: oneTimePassword,
        password: newPassword,
      };
      const handleSuccessFn = (res) => res.data;

      const resetResponse = await handlePostApiCall({
        url: "auth/reset-password",
        params,
        handleSuccessFn,
      });
      if (resetResponse.status == "success") {
        captureEvent(
          EVENTNAME.FORGOT_PASSWORD_OTP_COMPLETE_CLICK,
          SECTION.LOGIN_FORM
        );
        closeDialog();
      } else setErrorMessage(resetResponse.message);
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (isDialogOpen) {
      setErrorMessage("");
      setButtonDisabled(false);
      if (context == "LOGIN" || context == "REGISTER")
        getProfileFromAPI().then((profile) =>
          setUserEmail(profile.maskedEmail)
        );
      if (context == "FORGOT") setUserEmail(email);
    }
  }, [isDialogOpen]);

  return (
    <>
      <Box display="flex" flexGrow={1}>
        <Typography variant="text-base-semibold" color="neutral-900.main">
          Enter OTP sent to{" "}
        </Typography>
      </Box>
      {!disableClose && isMobile && (
        <IconButton
          onClick={handleCrossClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      <Typography
        variant="text-base-semibold"
        color="neutral-900.main"
        marginBottom="16px"
      >
        {userEmail || phoneNumberOrEmail}
      </Typography>
      {context == "FORGOT" && (
        <TextField
          className={styles.new_pass__input}
          marginTop="8px"
          placeholder="New password"
          variant="outlined"
          type={isPasswordVisible ? "text" : "password"}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPasswordClick}
                >
                  {isPasswordVisible ? (
                    <VisibilityIcon color="#A2A2A2" />
                  ) : (
                    <VisibilityOffIcon color="#A2A2A2" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-root": {
              xs: { height: "56px" },
              md: { height: "44px" },
            },
          }}
        />
      )}
      <OTPBoxes
        otpLen={OTP_LENGTH}
        setParentOtpValue={handleOtpChange}
        inputCtnClassName={styles.otp__form__fields__box}
        inputClassName={styles.otp_input_box}
      />
      {errorMessage && (
        <Typography
          variant="text-sm"
          color="rose-600.main"
          textAlign="center"
          sx={{
            marginBottom: "16px",
          }}
        >
          {errorMessage}
        </Typography>
      )}
      <ResendOTP
        setErrorMessageFn={setErrorMessage}
        phoneNumberOrEmail={phoneNumberOrEmail}
        context={context}
      />
      <Button
        className={styles.auth__form__button}
        disableElevation
        variant="contained"
        onClick={handleOtpSubmit}
        disabled={submitButtonDisabled}
      >
        <Typography
          variant="text-base-semibold"
          component="div"
          color="secondary"
        >
          {submitButtonDisabled ? (
            <CircularProgress color="secondary" size={30} thickness={5} />
          ) : (
            "Confirm OTP"
          )}
        </Typography>
      </Button>
    </>
  );
};

const AuthOTP = ({
  isDialogOpen,
  closeDialog,
  crossDialogFn,
  closeAuthDialog,
  context,
  email,
  disableDialog = false,
  phoneNumberOrEmail = "",
}) => {
  const isMobile = useIsMobile();

  if (disableDialog) {
    return (
      <AuthOTPForm
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
        crossDialogFn={crossDialogFn}
        closeAuthDialog={closeAuthDialog}
        context={context}
        email={email}
        disableClose={true}
        phoneNumberOrEmail={phoneNumberOrEmail}
      />
    );
  }

  return (
    <>
      {isMobile && (
        <Dialog
          open={isDialogOpen}
          TransitionComponent={Transition}
          PaperProps={{
            style:
              context == "FORGOT" ? mobileResetDialogStyle : mobileDialogStyle,
          }}
        >
          <Box className={styles.otp__form__box}>
            <AuthOTPForm
              isDialogOpen={isDialogOpen}
              closeDialog={closeDialog}
              crossDialogFn={crossDialogFn}
              closeAuthDialog={closeAuthDialog}
              context={context}
              email={email}
              phoneNumberOrEmail={phoneNumberOrEmail}
            />
          </Box>
        </Dialog>
      )}
      {!isMobile && (
        <AuthOTPForm
          isDialogOpen={isDialogOpen}
          closeDialog={closeDialog}
          closeAuthDialog={closeAuthDialog}
          context={context}
          email={email}
          phoneNumberOrEmail={phoneNumberOrEmail}
        />
      )}
    </>
  );
};

export default AuthOTP;
