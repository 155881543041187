var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"VERCEL_GIT_COMMIT_SHA"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import config from "./config.js";

const IN_DEV = config.ENVIRONMENT !== "production";

if (config.SENTRY_DSN != "" && config.SENTRY_DSN !== undefined) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    debug: IN_DEV,
    tracesSampleRate: 1.0,
    sampleRate: 0.3,
  });
}
