import Script from "next/script";
import config from "../../config";
import { checkIfInternalUser } from "../auth/profile";
import { EVENTNAME } from "./constants";

export default function GATag() {
  let gtagID = "G-7F8QCD65N4";
  if (config.ENVIRONMENT != "production") {
    gtagID = "G-BXP4FG77WJ";
  }

  const scriptSrc = `https://www.googletagmanager.com/gtag/js?id=${gtagID}`;

  return (
    <div>
      <Script strategy="afterInteractive" src={scriptSrc} />
      <Script strategy="afterInteractive" id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gtagID}');
        `}
      </Script>
    </div>
  );
}

export function captureGAWEvent(eventType) {
  if (eventType == "host-signup") {
    gtag("event", "conversion", {
      send_to: "AW-11026152867/yJ8vCPKs_4MYEKP71okp",
    });
    return;
  }

  if (eventType == "contact-host" || eventType == "contact-host-wa") {
    gtag("event", "conversion", {
      send_to: "AW-11026152867/Fj9pCO-s_4MYEKP71okp",
    });
  }

  if (eventType == EVENTNAME.SIGNUP_COMPLETE) {
    gtag("event", "conversion", {
      send_to: "AW-11026152867/XJBiCPSkya4YEKP71okp",
    });
  }
}

/**
 *
 * @param {EVENTNAME} eventName - EVENTNAME from ./constants
 * @param {SECTION} section - SECTION from ./constants
 * @param {object} eventData - Extra event data/params to be sent
 */
export function captureEvent(eventName, section, eventData = {}) {
  const isInternalUser = checkIfInternalUser();
  if (isInternalUser) {
    // Don't do anything, let all events come to GA
  }

  let params = {
    section: section,
    ...eventData,
  };
  if (config.ENVIRONMENT != "production") {
    params["debug_mode"] = true;
  }
  gtag("event", eventName, params);
}
