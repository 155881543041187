const initialState = {
  calendarData: {},
  page: 0,
  noMoreAvailableTrips: false,
};

const filterSearchedTripsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CALENDAR_DATA":
      return {
        ...state,
        trips: action.payload,
      };
    case "INCREMENT_PAGE_COUNT":
      return {
        ...state,
        page: state.page + 1,
      };
    case "SET_NO_MORE_AVAILABLE_TRIPS":
      return {
        ...state,
        noMoreAvailableTrips: true,
      };
    case "RESET_SEARCH_PAGINATION":
      return {
        ...state,
        page: initialState.page,
        noMoreAvailableTrips: initialState.noMoreAvailableTrips,
      };
    default:
      return state;
  }
};

export default filterSearchedTripsReducer;
