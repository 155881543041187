import { parseProfileObject } from "../auth/profile";

function getDefaultReviewObj() {
  const review = {
    rating: 0,
    review: "",
    customerName: null,
    id: 0,
    isVerified: false,
    profile: null,
    dateCreated: null,
    medias: [],
    isMine: false,
    customerNameInitials: "",
  };

  return review;
}

function parseReviewObj(reponse = {}) {
  const review = getDefaultReviewObj();

  review.rating = reponse.rating ?? review.rating;
  review.review = reponse.review ?? review.review;
  review.customerName = reponse.customer_name ?? review.customerName;
  review.id = reponse.id ?? review.id;
  review.isVerified = reponse.is_verified ?? review.isVerified;

  review.profile = reponse.profile
    ? parseProfileObject(reponse.profile)
    : review.profile;
  review.dateCreated = reponse.date_created ?? review.dateCreated;
  review.medias = reponse.medias ?? review.medias;
  review.isMine = reponse.is_mine ?? review.isMine;

  let customerNameInitialsToBe = [];
  if (review.profile?.firstName || review.profile?.lastName) {
    customerNameInitialsToBe = [
      review.profile?.firstName,
      review.profile?.lastName || "",
    ];
  } else if (review.profile?.displayName) {
    customerNameInitialsToBe = [review.profile?.displayName];
  } else customerNameInitialsToBe = review.customerName?.split(" ") ?? [];
  review.customerNameInitials =
    customerNameInitialsToBe
      ?.slice(0, 2)
      ?.map((name) => name.charAt(0).toUpperCase()) ?? "";

  return review;
}

function getDefaultRatingCountObj() {
  const ratingCount = {
    rating: 0,
    count: 0,
  };

  return ratingCount;
}

function parseRatingCountObj(response = {}) {
  const ratingCount = getDefaultRatingCountObj();

  ratingCount.rating = response.rating ?? ratingCount.rating;
  ratingCount.count = response.count ?? ratingCount.count;

  return ratingCount;
}

function getDefaultReviewsAndRatings() {
  const reviewsAndRatings = {
    reviews: [],
    averageRating: 0,
    ratingCounts: [],
    totalReviews: 0,
  };

  return reviewsAndRatings;
}

function parseReviewsAndRatings(response = {}) {
  const reviewsAndRatings = getDefaultReviewsAndRatings();

  reviewsAndRatings.reviews = response?.reviews
    ? response?.reviews?.map((review) => parseReviewObj(review))
    : reviewsAndRatings.reviews;
  reviewsAndRatings.averageRating =
    response?.average_rating ?? reviewsAndRatings.averageRating;
  reviewsAndRatings.ratingCounts = response?.rating_counts
    ? response?.rating_counts?.map((ratingCount) =>
        parseRatingCountObj(ratingCount)
      )
    : reviewsAndRatings.ratingCounts;
  reviewsAndRatings.totalReviews = reviewsAndRatings.ratingCounts.reduce(
    (acc, ratingCount) => acc + ratingCount.count,
    0
  );

  return reviewsAndRatings;
}

export { parseReviewObj, parseRatingCountObj, parseReviewsAndRatings };
