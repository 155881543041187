import FilterClass from "../filter.class";
import store from "../../../store";

const isEmptyFn = () => {
  return !store.getState().FilterReducer?.endDate;
};
const resetFn = () => {
  store.dispatch({
    type: "RESET_END_DATE_FILTER",
  });
};

function getValueStr() {
  return this.value && this.value() + " days";
}

function isValidHighlightStateFn() {
  return true;
  if (this.defaultValue === this.value()) return false;

  return true;
}

const defaultValue = 1;

export const EndDateFilterObj = new FilterClass({
  id: "1",
  reduxKey: "endDate",
  name: "end date",
  label: "End Date",
  prefix: "within",
  resetFn,
  isEmptyFn,
  getValueStr,
  defaultValue,
  isValidHighlightStateFn: isValidHighlightStateFn,
});
