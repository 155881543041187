import { useRouter } from "next/router";
import config from "../../config";
import MetaTags from "../head/metatags";
import { useDispatch, useSelector } from "react-redux";
import { GroupSizeFilterObj } from "../filter/group-size-filter/group.size.filter.obj";
import { StartDateFilterObj } from "../filter/date-filter/start.date.filter.obj";
import { returnValIfIntOrNull } from "../../utils/string";
import { getGroupSizeObjById } from "../filter/group-size-filter/group.size.obj";
import { TripBudgetFilterObj } from "../filter/trip-budget-filter/trip.budget.filter.obj";
import { SEARCH_CONTEXT } from "../../utils/search.context";

const SEARCH_ENTITY_TYPE = {
  HOST: "host",
  LOCATION: "location",
  TRIP_TYPE: "trip_type",
};

const SEARCH_EXTRA_FIELDS_PARAM = {
  TOTAL_TRIPS: "total_trips",
};

const FILTER_DATA_SOURCE = {
  SSR: "ssr",
  CLIENT: "client",
};

function useGetSearchPageMetaParams({
  entity_type,
  entity_details = {},
  tripsResult = [],
  totalTrips = 0,
}) {
  const router = useRouter();
  let pageTitle = "Upcoming trips by hosts and destinations";
  let metaParamDescription = `Search upcoming tour, spiritual and adventure trips in India within your budget and connect directly with the trip hosts on Cohyk`;
  let permalink = null;
  const maxDiscountValue = tripsResult.reduce(
    (prev, cur) => (cur.discountValue > prev ? cur.discountValue : prev),
    0
  );

  if (entity_type === SEARCH_ENTITY_TYPE.LOCATION) {
    permalink = `${config.HOST}/location/${router.query.slug}/upcoming-trips`;
    if (entity_details?.id) {
      pageTitle = `Trips & Experiences To ${entity_details.name}`;
      metaParamDescription = `Upcoming group trips, tours and experiences to ${entity_details.name}, ${entity_details.region} from variety of hosts at best prices with best offers on Cohyk.`;
    }
  } else if (entity_type === SEARCH_ENTITY_TYPE.TRIP_TYPE) {
    permalink = `${config.HOST}/${entity_details.slug}-trips`;
    if (entity_details?.id) {
      pageTitle = `${entity_details.name} Trips in India`;
      metaParamDescription = `Upcoming ${entity_details.name} trips and experiences at best prices with best offers on Cohyk.`;
    }
  } else if (entity_type === SEARCH_ENTITY_TYPE.HOST) {
    permalink = `${config.HOST}/host/${router.query.handle}/upcoming-trips`;
    if (entity_details?.id) {
      pageTitle = `Trips & Experiences With ${entity_details.name}`;
      metaParamDescription = `Upcoming group trips, tours and experiences with ${entity_details.name} at best prices with best offers on Cohyk.`;
    }
  }
  // else if (entity_type === SEARCH_ENTITY_TYPE.TRIP_TYPE) {
  //   permalink = `${config.HOST}/${entity_details.slug}-trips`;
  //   if (entity_details?.id) {
  //     pageTitle = `${entity_details.name} Trips in India`;
  //     metaParamDescription = `Upcoming ${entity_details.name} trips and experiences at best prices with best offers on Cohyk.`;
  //   }
  // }

  if (entity_details?.id) {
    if (totalTrips) {
      pageTitle = `${totalTrips} ${pageTitle}`;
    }

    if (maxDiscountValue) {
      pageTitle = `${pageTitle}, Upto ${maxDiscountValue} Off`;
    }
  }

  const metaParams = new MetaTags({
    description: metaParamDescription,
    imageURL: `https://cohyk.mo.cloudinary.net/op-v1/images/logo.png`,
    permalink: permalink,
    keywords: [
      `trips search result`,
      `upcoming trips`,
      `trips with host`,
      `adventure trips`,
      `tours in India`,
    ],
  });

  return [pageTitle, metaParams];
}

// Only paths on which redirections should be done,
// ex, setting destination on home page shouldn't redirect
const pathsForRedirections = [
  "/search/trips",
  "/host/[handle]/upcoming-trips",
  "/[trip_type]",
  "/location/[slug]/upcoming-trips",
];

const pathsForDestinationRedirections = [...pathsForRedirections, "/"];

function useSearchFilterUrlManager({ entityType, replaceUrl = true }) {
  const router = useRouter();
  const filterReducer = useSelector((state) => state.FilterReducer);
  const dispatch = useDispatch();
  function baseUrlHandler(redirectUrl, redirectToEntity) {
    if (redirectUrl && redirectUrl !== window.location.pathname) {
      const filterQueries = getFiltersAsQueryObj(filterReducer);
      delete filterQueries["host"];

      if (redirectToEntity === "tripType") {
        delete filterQueries["trip-type"];
      } else if (redirectToEntity === "destination") {
        delete filterQueries["destination"];
      }

      const routerMethod = replaceUrl ? router.replace : router.push;
      routerMethod(
        {
          pathname: redirectUrl,
          query: filterQueries,
        },
        undefined,
        {
          scroll: false,
        }
      );
    }
  }

  if (entityType === SEARCH_ENTITY_TYPE.HOST) {
    function filterChangeHandler(hostDetails) {
      if (!pathsForRedirections.some((path) => path === router.pathname)) {
        dispatch({
          type: "SET_HOST_FILTER",
          payload: {
            details: {
              ...hostDetails,
            },
            dataSource: FILTER_DATA_SOURCE.CLIENT,
          },
        });
      } else {
        baseUrlHandler(hostDetails?.trips_url_path, "host");
      }
    }

    return filterChangeHandler;
  } else if (entityType === SEARCH_ENTITY_TYPE.TRIP_TYPE) {
    function filterChangeHandler(tripTypeDetails) {
      if (
        !pathsForRedirections.some((path) => path === router.pathname) ||
        filterReducer?.host?.details?.id
      ) {
        dispatch({
          type: "SET_TRIP_TYPE_FILTER",
          payload: {
            details: {
              ...tripTypeDetails,
            },
            dataSource: FILTER_DATA_SOURCE.CLIENT,
          },
        });
      } else {
        baseUrlHandler(tripTypeDetails?.trips_url_path, "tripType");
      }
    }

    return filterChangeHandler;
  } else if (entityType === SEARCH_ENTITY_TYPE.LOCATION) {
    function filterChangeHandler(locationDetails) {
      if (
        !pathsForDestinationRedirections.some(
          (path) => path === router.pathname
        ) ||
        filterReducer?.host?.details?.id ||
        filterReducer?.tripType?.details?.id
      ) {
        dispatch({
          type: "SET_DESTINATION_LOCATION_FILTER",
          payload: {
            details: {
              ...locationDetails,
            },
            dataSource: FILTER_DATA_SOURCE.CLIENT,
          },
        });
      } else {
        baseUrlHandler(locationDetails.trips_url_path, "destination");
      }
    }

    return filterChangeHandler;
  }
}

function getFiltersAsQueryObj(filterData = {}) {
  const filterQueries = {};

  if (filterData.startDate) {
    filterQueries["start-date"] = filterData.startDate;
  }
  if (filterData?.startingLocation?.id) {
    filterQueries["starting"] = filterData?.startingLocation?.id;
  } else delete filterQueries["starting"];
  if (filterData?.destinationLocation?.details?.id) {
    filterQueries["destination"] = filterData?.destinationLocation?.details?.id;
  } else delete filterQueries["destination"];
  if (filterData?.host?.details?.id) {
    filterQueries["host"] = filterData?.host?.details?.id;
  } else delete filterQueries["host"];
  if (filterData?.tripType?.details?.id) {
    filterQueries["trip-type"] = filterData?.tripType?.details?.slug;
  } else delete filterQueries["trip-type"];
  if (
    filterData.groupSize?.id &&
    filterData.groupSize?.id !== GroupSizeFilterObj.defaultValue?.id
  ) {
    filterQueries["group-size"] = filterData.groupSize?.id;
  } else delete filterQueries["group-size"];
  if (filterData.tripBudget) {
    filterQueries["budget"] = filterData.tripBudget;
  } else delete filterQueries["budget"];
  if (filterData.verifiedHost) {
    filterQueries["verified-host"] = true;
  } else delete filterQueries["verified-host"];
  if (filterData.womenOnly) {
    filterQueries["women-only"] = true;
  } else delete filterQueries["women-only"];
  if (filterData.hasDiscount) {
    filterQueries["with-discount"] = true;
  } else delete filterQueries["with-discount"];

  return filterQueries;
}

/**
 * @typedef {Object} URLSearchParams
 * @property {string} startDate
 * @property {number | null} destinationLocationId
 * @property {string | null} tripTypeSlug
 * @property {string | null} startingLocationId
 * @property {number | null} hostId
 * @property {boolean} womenOnly
 * @property {boolean} verifiedHost
 * @property {number | null} budget
 * @property {boolean} hasDiscount
 * @property {import("../filter/group-size-filter/group.size.obj").GroupSizeObj} groupSize
 */

/**
 * @param {import("next").GetServerSidePropsContext} context
 * @returns {URLSearchParams}
 */
function getParsedServerSideURLParams(context) {
  return {
    startDate: context.query["start-date"] || StartDateFilterObj.defaultValue,
    destinationLocationId: returnValIfIntOrNull(context.query.destination),
    tripTypeSlug: context.query["trip-type"] || "",
    startingLocationId: returnValIfIntOrNull(context.query.starting),
    hostId: returnValIfIntOrNull(context.query.host),
    womenOnly: context.query["women-only"]?.toLowerCase() === "true",
    verifiedHost: context.query["verified-host"]?.toLowerCase() === "true",
    budget: returnValIfIntOrNull(context.query.budget),
    hasDiscount: context.query["has-discount"]?.toLowerCase() === "true",
    groupSize: getGroupSizeObjById({ id: context.query.groupSize }),
  };
}

/**
 * @param {import("next").GetServerSidePropsContext} context
 * @param {URLSearchParams} params
 * @param {any} tripType
 * @param {"search" | "host" | "tripType" | "destination"} pageContext
 */
function getSearchRequestServerSideParams(
  context,
  params,
  tripType,
  pageContext
) {
  const searchTripParams = {
    start_date: params.startDate,
    limit: 20,
    page: 0,
    cost: TripBudgetFilterObj.defaultValue,
  };

  switch (pageContext) {
    case "search":
      searchTripParams.context = SEARCH_CONTEXT.SEARCH;
      break;
    case "host":
      searchTripParams.search_by_entity_slug = {
        entity_type: SEARCH_ENTITY_TYPE.HOST,
        entity_slug: context.params.handle,
      };
      break;
    case "destination":
      searchTripParams.search_by_entity_slug = {
        entity_type: SEARCH_ENTITY_TYPE.LOCATION,
        entity_slug: context.params.slug,
      };
      break;
  }

  if (params.destinationLocationId) {
    searchTripParams.highlight_location_id = params.destinationLocationId;
  }
  if (params.startingLocationId) {
    searchTripParams.starting_location_id = params.startingLocationId;
  }
  if (params.hostId) {
    searchTripParams.host_id = params.hostId;
  }
  if (tripType) {
    searchTripParams.trip_type = tripType?.id;
  }
  if (params.womenOnly) {
    searchTripParams.women_only = params.womenOnly;
  }
  if (params.verifiedHost) {
    searchTripParams.verified_host = params.verifiedHost;
  }
  if (params.budget) {
    searchTripParams.cost = params.budget;
  }
  if (params.hasDiscount) {
    searchTripParams.has_discount = true;
  }
  if (params.groupSize?.id) {
    searchTripParams.group_size = params.groupSize.id;
  }

  return searchTripParams;
}

export {
  SEARCH_ENTITY_TYPE,
  SEARCH_EXTRA_FIELDS_PARAM,
  useGetSearchPageMetaParams,
  FILTER_DATA_SOURCE,
  useSearchFilterUrlManager,
  getFiltersAsQueryObj,
  getParsedServerSideURLParams,
  getSearchRequestServerSideParams,
};
