const initialState = {
  // for web chat dialog
  isOpen: false,
  messengerProfile: null,
  messages: [],
  channelData: {
    channels: [],
    activeChannel: null,
    // stores `channel_id`: `read_at_ts` mapping.
    // except when the `channel_id` is of the activeChannel's, store the last_msg_ts
    channelLastReadTs: {},
    haveChannelsLoaded: false,
    channelLastMsgTs: {},
    // Temp channel created when user chooses to send a message to a host, but a channel between them doesn't exist yet
    // fields: name, recipientHandle
    tempChannel: null,
  },
  scrollData: {
    shouldScroll: true,
    behavior: "instant",
  },
  mediaViewerData: {
    isOpen: false,
    image: "",
  },
  // for status change dialog, holding chosen status id and active until date
  statusChangeData: {
    isOptionsOpen: false,
    isDatePickerOpen: false,
    statusId: null,
    statusActiveUntil: new Date().setDate(new Date().getDate() + 1),
  },
  cohykAgentData: {
    agentDetails: null,
    isAgentMode: false,
    isAgentModeLoading: false,
    hostData: null,
    hostChannels: [],
  },
  isFetchingMessages: false,
};

const ChatReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "OPEN_CHAT":
      return {
        ...state,
        isOpen: true,
      };

    case "CLOSE_CHAT":
      return {
        ...state,
        isOpen: initialState.isOpen,
      };

    case "SET_MESSENGER_PROFILE":
      return {
        ...state,
        messengerProfile: payload,
      };

    case "RESET_MESSENGER_PROFILE":
      return {
        ...state,
        messengerProfile: initialState.messengerProfile,
      };

    case "SET_CHANNELS":
      return {
        ...state,
        // channels: payload,
        channelData: {
          ...state.channelData,
          channels: payload,
        },
      };

    case "RESET_CHANNELS":
      return {
        ...state,
        // channels: initialState.channels,
        channelData: {
          ...state.channelData,
          channels: initialState.channelData.channels,
        },
      };

    case "SET_ACTIVE_CHANNEL":
      return {
        ...state,
        // activeChannel: payload,
        channelData: {
          ...state.channelData,
          activeChannel: payload,
        },
      };

    case "RESET_ACTIVE_CHANNEL":
      return {
        ...state,
        // activeChannel: initialState.activeChannel,
        channelData: {
          ...state.channelData,
          activeChannel: initialState.channelData.activeChannel,
        },
      };

    case "SET_CHANNEL_LAST_READ_TS":
      return {
        ...state,
        channelData: {
          ...state.channelData,
          channelLastReadTs: payload,
        },
      };

    case "RESET_CHANNEL_LAST_READ_TS":
      return {
        ...state,
        channelData: {
          ...state.channelData,
          channelLastReadTs: initialState.channelData.channelLastReadTs,
        },
      };

    case "SET_CHANNELS_HAVE_LOADED":
      return {
        ...state,
        channelData: {
          ...state.channelData,
          haveChannelsLoaded: true,
        },
      };

    case "RESET_CHANNELS_HAVE_LOADED":
      return {
        ...state,
        channelData: {
          ...state.channelData,
          haveChannelsLoaded: initialState.channelData.haveChannelsLoaded,
        },
      };

    case "SET_CHANNEL_LAST_MSG_TS":
      return {
        ...state,
        channelData: {
          ...state.channelData,
          channelLastMsgTs: payload,
        },
      };

    case "RESET_CHANNEL_LAST_MSG_TS":
      return {
        ...state,
        channelData: {
          ...state.channelData,
          channelLastMsgTs: initialState.channelData.channelLastMsgTs,
        },
      };

    case "SET_MESSAGES":
      return {
        ...state,
        messages: payload,
      };

    case "ADD_MESSAGE":
      return {
        ...state,
        messages: [...state.messages, payload],
      };

    case "RESET_MESSAGES":
      return {
        ...state,
        messages: initialState.messages,
      };

    case "SET_SCROLL_DATA":
      return {
        ...state,
        scrollData: payload,
      };

    case "RESET_SCROLL_DATA":
      return {
        ...state,
        scrollData: initialState.scrollData,
      };

    case "SET_MEDIA_VIEWER_DATA":
      return {
        ...state,
        mediaViewerData: payload,
      };

    case "RESET_MEDIA_VIEWER_DATA":
      return {
        ...state,
        mediaViewerData: initialState.mediaViewerData,
      };

    case "SET_STATUS_CHANGE_DATA":
      return {
        ...state,
        statusChangeData: payload,
      };

    case "RESET_STATUS_CHANGE_DATA":
      return {
        ...state,
        statusChangeData: initialState.statusChangeData,
      };

    case "SET_TEMP_CHANNEL":
      return {
        ...state,
        channelData: {
          ...state.channelData,
          tempChannel: payload,
        },
      };

    case "RESET_TEMP_CHANNEL":
      return {
        ...state,
        channelData: {
          ...state.channelData,
          tempChannel: initialState.channelData.tempChannel,
        },
      };

    case "SET_COHYK_AGENT_DETAILS":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          agentDetails: payload,
        },
      };

    case "RESET_COHYK_AGENT_DETAILS":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          agentDetails: initialState.cohykAgentData.agentDetails,
        },
      };

    case "SET_COHYK_AGENT_MODE":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          isAgentMode: true,
        },
      };

    case "RESET_COHYK_AGENT_MODE":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          isAgentMode: initialState.cohykAgentData.isAgentMode,
        },
      };

    case "SET_COHYK_AGENT_MODE_LOADING":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          isAgentModeLoading: payload ?? true,
        },
      };

    case "RESET_COHYK_AGENT_MODE_LOADING":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          isAgentModeLoading: initialState.cohykAgentData.isAgentModeLoading,
        },
      };

    case "SET_COHYK_AGENT_HOST_DATA":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          hostData: payload,
        },
      };

    case "RESET_COHYK_AGENT_HOST_DATA":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          hostData: initialState.cohykAgentData.hostData,
        },
      };

    case "SET_COHYK_AGENT_HOST_CHANNELS":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          hostChannels: payload,
        },
      };

    case "RESET_COHYK_AGENT_HOST_CHANNELS":
      return {
        ...state,
        cohykAgentData: {
          ...state.cohykAgentData,
          hostChannels: initialState.cohykAgentData.hostChannels,
        },
      };

    case "SET_IS_FETCHING_MESSAGES":
      return {
        ...state,
        isFetchingMessages: true,
      };

    case "RESET_IS_FETCHING_MESSAGES":
      return {
        ...state,
        isFetchingMessages: false,
      };

    default:
      return state;
  }
};

export default ChatReducer;
