import FilterClass from "../filter.class";
import store from "../../../store";
import Location from "../../location/location";

const isEmptyFn = () => {
  return !store.getState().FilterReducer?.destinationLocation?.details?.name;
};
// this function will be for cancel button
const resetFn = () => {
  store.dispatch({
    type: "RESET_DESTINATION_LOCATION_FILTER",
  });
};
function getValueStr() {
  return this.value && this.value()?.name;
}

function isValidDestinationStateFn() {
  if (this.defaultValue.id === this.value().id) return false;

  return true;
}

const defaultValue = new Location({ id: "", name: "" });

export const HighlightLocationFilterObj = new FilterClass({
  id: "1",
  reduxKey: "destinationLocation",
  name: "destination location",
  label: "Destination Location",
  prefix: "",
  resetFn,
  isEmptyFn,
  getValueStr,
  defaultValue,
  isValidDestinationStateFn : isValidDestinationStateFn
});
