import TripType from "./trip.type.class";
import Host from "../Host/host";
import Location from "../location/location";
import { StartingLocationFilterObj } from "./starting-location-filter/starting.location.filter.obj";
import { HighlightLocationFilterObj } from "./destination-filter/highlight.location.filter.obj";
import { StartDateFilterObj } from "./date-filter/start.date.filter.obj";
import { EndDateFilterObj } from "./date-filter/end.date.filter.obj";
import { TripBudgetFilterObj } from "./trip-budget-filter/trip.budget.filter.obj";
import { TripTypeFilterObj } from "./trip-type-filter/trip.type.filter.obj";
import { HostFilterObj } from "./host-filter/host.filter.obj";
import { GroupSizeFilterObj } from "./group-size-filter/group.size.filter.obj";
import { parseGroupSizeObj } from "./group-size-filter/group.size.obj";
import { FILTER_DATA_SOURCE } from "../search/common";

const initialState = {
  startingLocation: StartingLocationFilterObj.defaultValue,
  destinationLocation: HighlightLocationFilterObj.defaultValue,
  startDate: StartDateFilterObj.defaultValue,
  endDate: EndDateFilterObj.defaultValue,
  tripBudget: TripBudgetFilterObj.defaultValue,
  tripType: TripTypeFilterObj.defaultValue,
  host: HostFilterObj.defaultValue,
  verifiedHost: false,
  womenOnly: false,
  hasDiscount: false,
  filtersLoaded: false,
  groupSize: GroupSizeFilterObj.defaultValue,
};

const FilterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_FILTER_DIALOG_DATA": {
      return payload.state;
    }
    case "SET_STARTING_LOCATION_FILTER": {
      const { id, name, description } = payload;
      let startingLocation = new Location({ id, name, description });
      let newState = { ...state };
      newState.startingLocation = startingLocation;
      return newState;
    }
    case "RESET_STARTING_LOCATION_FILTER": {
      let startingLocation = initialState.startingLocation;
      let newState = { ...state };
      newState.startingLocation = startingLocation;
      return newState;
    }
    case "SET_DESTINATION_LOCATION_FILTER": {
      // const {
      //   id,
      //   name,
      //   description,
      //   url_path,
      //   photos,
      //   region_type,
      //   region,
      //   trips_url_path,
      // } = payload;
      const {
        details: {
          id,
          name,
          description,
          url_path,
          photos,
          region_type,
          region,
          trips_url_path,
        },
        dataSource = FILTER_DATA_SOURCE.CLIENT,
      } = payload;
      let destinationLocation = new Location({
        id,
        name,
        description,
        url_path,
        photos,
        region_type,
        region,
        trips_url_path,
      });
      let newState = { ...state };
      newState.destinationLocation = {
        details: destinationLocation,
        dataSource,
      };
      return newState;
    }
    case "RESET_DESTINATION_LOCATION_FILTER": {
      let destinationLocation = initialState.destinationLocation;
      let newState = { ...state };
      newState.destinationLocation = destinationLocation;
      return newState;
    }
    case "SET_START_DATE_FILTER": {
      const { date } = payload;
      let newState = { ...state };
      newState.startDate = date;
      return newState;
    }
    case "RESET_START_DATE_FILTER": {
      let newState = { ...state };
      newState.startDate = initialState.startDate;
      return newState;
    }
    case "SET_END_DATE_FILTER": {
      const { endDate } = payload;
      let newState = { ...state };
      newState.endDate = endDate;
      return newState;
    }
    case "RESET_END_DATE_FILTER": {
      let newState = { ...state };
      newState.endDate = initialState.endDate;
      return newState;
    }
    case "SET_TRIP_BUDGET_FILTER": {
      const { tripBudget } = payload;
      let newState = { ...state };
      newState.tripBudget = tripBudget;
      return newState;
    }
    case "RESET_TRIP_BUDGET_FILTER": {
      let newState = { ...state };
      newState.tripBudget = initialState.tripBudget;
      return newState;
    }
    case "SET_TRIP_TYPE_FILTER": {
      const {
        details: { id, name, description, emoji, slug, trips_url_path },
        dataSource = FILTER_DATA_SOURCE.CLIENT,
      } = payload;
      let newState = { ...state };
      newState.tripType = {
        details: new TripType({
          id,
          name,
          description,
          emoji,
          slug,
          trips_url_path,
        }),
        dataSource,
      };
      return newState;
    }
    case "RESET_TRIP_TYPE_FILTER": {
      let newState = { ...state };
      newState.tripType = initialState.tripType;
      return newState;
    }
    case "SET_HOST_FILTER": {
      const {
        details: { id, name, instagram_handle, trips_url_path },
        dataSource = FILTER_DATA_SOURCE.CLIENT,
      } = payload;
      const newHost = new Host();
      newHost.id = id;
      newHost.name = name;
      newHost.instagramHandle = instagram_handle;
      newHost.tripsUrlPath = trips_url_path;

      let newState = { ...state };
      newState.host = { details: newHost, dataSource };
      return newState;
    }
    case "RESET_HOST_FILTER": {
      let newState = { ...state };
      newState.host = initialState.host;
      return newState;
    }
    case "SET_VERIFIED_HOST_FILTER": {
      const { verifiedHost } = payload;
      return { ...state, verifiedHost };
    }
    case "RESET_VERIFIED_HOST_FILTER": {
      return { ...state, verifiedHost: initialState.verifiedHost };
    }
    case "SET_WOMEN_ONLY_FILTER": {
      const { womenOnly } = payload;
      return { ...state, womenOnly };
    }
    case "RESET_WOMEN_ONLY_FILTER": {
      return { ...state, womenOnly: initialState.womenOnly };
    }
    case "SET_DISCOUNT_FILTER": {
      const { hasDiscount } = payload;
      return { ...state, hasDiscount };
    }
    case "RESET_DISCOUNT_FILTER": {
      return { ...state, hasDiscount: initialState.hasDiscount };
    }
    case "SET_GROUP_SIZE_FILTER": {
      const { id, name, description } = payload;
      const newState = { ...state };
      const groupSize = parseGroupSizeObj({ id, name, description });
      newState.groupSize = groupSize;
      return newState;
    }
    case "RESET_GROUP_SIZE_FILTER":
      const newState = { ...state };
      newState.groupSize = initialState.groupSize;
      return newState;
    case "RESET_ALL_FILTERS": {
      return {
        ...initialState,
        filtersLoaded: true,
      };
    }
    case "SET_FILTERS_LOADED":
      return {
        ...state,
        filtersLoaded: true,
      };
    case "SET_FILTERS_NOT_LOADED":
      return {
        ...state,
        filtersLoaded: false,
      };
    default:
      return state;
  }
};

export default FilterReducer;
