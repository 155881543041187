import { combineReducers } from "redux";
import filterSearchedTrips from "../components/calendar/filterSearchedTrips.reducer";
import FilterReducer from "../components/filter/filter.reducer";
import FilterDialogReducer from "../components/filter-dialog/filterdialog.reducer";
import AuthReducer from "../components/auth/auth.reducer";
import FeedbackReducer from "../components/navbar/components/feedback/feedback.reducer";
import ChatReducer from "../components/chat/chat.reducer";

export default combineReducers({
  filterSearchedTrips,
  FilterReducer,
  FilterDialogState: FilterDialogReducer,
  AuthReducer,
  FeedbackReducer,
  ChatReducer,
});
