import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import {
  Dialog,
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
// import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Image from "next/image";
import styles from "./auth.module.css";
import AuthOTP from "./otp.auth";
import useIsMobile from "../../utils/device.type.hook";
import { handleGetApiCall, handlePostApiCall } from "../../utils/api.call";
import { captureEvent, captureGAWEvent } from "../google-analytics/tag";
import { EVENTNAME, SECTION } from "../google-analytics/constants";
import GoogleIcon from "../../public/images/icons/google_white_colored.svg";
import config from "../../config";
import { toast } from "react-toastify";
import { checkIfInternalUser, getProfileFromAPI } from "./profile";
import { basicEmailValidation } from "../../utils/string";

const oAuthElementData = [
  {
    provider: "google",
    className: styles.login__oauth_google_btn,
    icon: GoogleIcon,
    buttonTextFn: (authType = AUTH_TYPE.LOGIN) =>
      (authType === AUTH_TYPE.LOGIN && "Login with Google") ||
      (authType === AUTH_TYPE.REGISTER && "Create Account with Google"),
  },
];

function OAuthLoginBtn({
  oAuthElementData,
  handlePostLoginSuccess = () => {},
  authType = AUTH_TYPE.LOGIN,
}) {
  const router = useRouter();
  const [authLink, setAuthLink] = useState("");
  const [isLinkLoading, setIsLinkLoading] = useState(true);

  useEffect(() => {
    handleGetApiCall(
      `auth/oauth/${oAuthElementData.provider}/grant-permissions`,
      {},
      (res) => {
        setAuthLink(res?.data);
      }
    ).finally(() => setIsLinkLoading(false));

    function handleAuthMessageEvent(e) {
      if (e.origin !== config.HOST) {
        return;
      }

      if (e.data.status === "success") {
        if (e.data.context === "signup" && !checkIfInternalUser()) {
          captureEvent(EVENTNAME.SIGNUP_COMPLETE, SECTION.LOGIN_FORM);
          captureGAWEvent(EVENTNAME.SIGNUP_COMPLETE);
        }
        handlePostLoginSuccess();
      } else {
        if (e.data.action === "redirect") {
          router.replace("/");
        } else {
          toast(e.data.message, {
            className: "Toastify__toast__background-danger",
            bodyClassName: "toast__body",
            autoClose: 3000,
          });
        }
      }
    }

    window.addEventListener("message", handleAuthMessageEvent);

    return () => window.removeEventListener("message", handleAuthMessageEvent);
  }, []);

  function handleOAuthLoginClick() {
    if (isLinkLoading) return;

    window.open(authLink, "_blank", "popup=1,width=600,height=800");
    captureEvent(EVENTNAME.OAUTH_LOGIN_CLICK_GOOGLE, SECTION.LOGIN_FORM);
  }

  return (
    <Button
      className={[
        styles.login__oauth_btn__base,
        oAuthElementData.className,
      ].join(" ")}
      onClick={handleOAuthLoginClick}
    >
      {isLinkLoading ? (
        <CircularProgress size={22} color="secondary" />
      ) : (
        <>
          {oAuthElementData.buttonTextFn(authType)}
          <Image src={oAuthElementData.icon} priority />
        </>
      )}
    </Button>
  );
}

const AUTH_TYPE = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
};

const AUTH_CONTEXT = {
  // Default: Login CTA
  DEFAULT: "DEFAULT",
  CONTACT_HOST: "CONTACT_HOST",
  BOOKING: "BOOKING",
};

/**
 *
 * @param {AUTH_CONTEXT} context
 * @param {object | null} hostDetails
 * @returns {object} copy
 */
function getCreateAccountCopy(
  context,
  hostDetails = null,
  bookingCurrency = null,
  bookingCost = null
) {
  if (context === AUTH_CONTEXT.CONTACT_HOST) {
    return `to chat directly with ${hostDetails?.name}. Also get special discounts on booking.`;
  } else if (context === AUTH_CONTEXT.BOOKING) {
    let text = `to book a seat with ${hostDetails?.name} or to chat with them.`;
    if (bookingCurrency && bookingCost) {
      text = `${text} As a bonus get ${bookingCurrency}${bookingCost} off on your booking.`;
    }
    return text;
  }

  // AUTH_CONTEXT.DEFAULT
  return "to chat directly with hosts, unlock special discounts and deals and get notified about trips that you would love.";
}

const Login = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const isLoginModalOpen = useSelector(
    (state) => state.AuthReducer.isLoginModalOpen
  );
  const authType = useSelector((state) => state.AuthReducer.authType);
  const authContext = useSelector((state) => state.AuthReducer.authContext);
  const authHostDetails = useSelector(
    (state) => state.AuthReducer.authHostDetails
  );
  const authBookingCurrency = useSelector(
    (state) => state.AuthReducer.authBookingCurrency
  );
  const authBookingCost = useSelector(
    (state) => state.AuthReducer.authBookingCost
  );

  const initLoginDetails = {
    email: "",
  };
  const [loginDetails, setLoginDetails] = useState(initLoginDetails);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [OTPScreenContext, setOTPScreenContext] = useState("LOGIN");
  const [submitButtonDisabled, setButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (e) => {
    setLoginDetails((prev) => {
      return {
        ...prev,
        email: e.target.value,
      };
    });
  };

  const closeOtpDialog = () => setOtpDialogOpen(false);
  const handleCrossOtpDialogClick = () =>
    captureEvent(EVENTNAME.LOGIN_OTP_CANCEL_CLICK, SECTION.LOGIN_FORM);

  const closeLoginDialog = () => {
    dispatch({ type: "CLOSE_LOGIN_MODAL" });
    setErrorMessage("");
  };
  const closeLoginWebDialog = () => {
    if (otpDialogOpen) return;
    closeLoginDialog();
    setOtpDialogOpen(false);
  };
  const handleCrossLoginDialogClick = () =>
    captureEvent(EVENTNAME.LOGIN_CANCEL_CLICK, SECTION.LOGIN_FORM);

  const afterLoginHandler = (redirect_url) => {
    if (redirect_url && redirect_url !== "/reload") {
      closeLoginDialog();
      router.push(redirect_url);
      return;
    }
    /*
    After BaseLayout mounts, 
    BaseLayout dispatches isProfileLoading = true -> /profile/me call -> dispatches isProfileLoading = false
    After logging in/registering/signing-up, in the cases where user is redirected
    to /create-host|/dashboard{/analytics|/manage-trips}, since the layouts of both links inherit BaseLayout,
    upon mounting BaseLayout does it's things
    But before BaseLayout dispatches isProfileLoading = true, the consumer's of the profile in Redux, 
    read isProfileLoading as false
    */
    dispatch({ type: "SET_PROFILE_LOADING" });
    getProfileFromAPI().then((profile) => {
      if (!profile?.id) {
        setErrorMessage(
          <>
            Something went wrong!{" "}
            <span
              style={{
                color: "#26A77C",
                cursor: "pointer",
              }}
              onClick={() => router.reload()}
            >
              Click here
            </span>{" "}
            to refresh the page{" "}
          </>
        );
        return;
      }
      dispatch({
        type: "SET_USER_PROFILE",
        payload: profile,
      });
      dispatch({ type: "SET_PROFILE_LOADED" });
      closeLoginDialog();
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    setErrorMessage("");

    if (!isNaN(loginDetails?.email)) {
      if (
        loginDetails?.email.length !== 10 ||
        loginDetails?.email?.startsWith("+")
      ) {
        setErrorMessage("Please enter a valid phone number");
        setButtonDisabled(false);
        return;
      }
    } else if (!basicEmailValidation(loginDetails?.email)) {
      setErrorMessage("Please enter a valid email");
      setButtonDisabled(false);
      return;
    }

    captureEvent(EVENTNAME.LOGIN_CLICK, SECTION.LOGIN_FORM);

    if (!loginDetails.email) {
      setErrorMessage(
        "Please enter your phone number or email above to create an account or login"
      );
      setButtonDisabled(false);
      return;
    }

    const params = {
      phone_number_or_email: loginDetails.email,
      context: "LOGIN",
    };
    const handleSuccessFn = (res) => res.data;

    const loginResponse = await handlePostApiCall({
      url: "auth/login",
      params,
      handleSuccessFn,
    });

    if (loginResponse.status == "success") {
      setOTPScreenContext("LOGIN");
      setOtpDialogOpen(true);
    } else setErrorMessage(loginResponse?.detail?.message);
    setButtonDisabled(false);
  };

  function handleCreateAccountCtaClick() {
    // captureEvent(EVENTNAME.CREATE_ACCOUNT_CLICK, SECTION.LOGIN_FORM);
    dispatch({
      type: "OPEN_LOGIN_MODAL",
      payload: {
        authType: AUTH_TYPE.REGISTER,
        authContext: authContext,
        hostDetails: authHostDetails,
        bookingCost: authBookingCost,
        bookingCurrency: authBookingCurrency,
      },
    });
  }

  function handleLoginCtaClick() {
    // captureEvent(EVENTNAME.LOGIN_CLICK, SECTION.LOGIN_FORM);
    dispatch({
      type: "OPEN_LOGIN_MODAL",
      payload: {
        authType: AUTH_TYPE.LOGIN,
        authContext: authContext,
        hostDetails: authHostDetails,
        bookingCost: authBookingCost,
        bookingCurrency: authBookingCurrency,
      },
    });
  }

  return (
    <>
      <Dialog
        open={isLoginModalOpen}
        PaperProps={{
          className: styles.authDialog__paper,
        }}
      >
        <Box className={styles.authDialog__headerCtn}>
          <Box className={styles.authDialog__headerSubCtn}>
            <Typography className={styles.authDialog__headerCtn__title}>
              {(authType === AUTH_TYPE.LOGIN && "Login") ||
                (authType === AUTH_TYPE.REGISTER && "Create account")}
            </Typography>
            <IconButton
              className={styles.authDialog__headerCtn__closeBtn}
              onClick={() => {
                closeLoginWebDialog();
                handleCrossLoginDialogClick();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {authType === AUTH_TYPE.LOGIN && (
            <Typography className={styles.authDialog__headerSubText}>
              Log in to view your chats and bookings
            </Typography>
          )}
          {authType === AUTH_TYPE.REGISTER && (
            <Typography className={styles.authDialog__headerSubText}>
              {getCreateAccountCopy(
                authContext,
                authHostDetails,
                authBookingCurrency,
                authBookingCost
              )}
            </Typography>
          )}
        </Box>
        {otpDialogOpen ? (
          <Box className={styles.authDialog__otpCtn}>
            <AuthOTP
              isDialogOpen={otpDialogOpen}
              closeDialog={closeOtpDialog}
              crossDialogFn={handleCrossOtpDialogClick}
              closeAuthDialog={closeLoginDialog}
              context={OTPScreenContext}
              email={loginDetails.email}
              disableDialog
              phoneNumberOrEmail={loginDetails.email}
            />
          </Box>
        ) : (
          <Box className={styles.authDialog__layoutCtn}>
            <Box className={styles.login__oauth_btns_ctn}>
              {oAuthElementData.map((data, i) => (
                <OAuthLoginBtn
                  key={i}
                  router={router}
                  oAuthElementData={data}
                  handlePostLoginSuccess={afterLoginHandler}
                  authType={authType}
                />
              ))}
            </Box>
            <Typography className={styles.login__oauth_or_text}>OR</Typography>
            <Box className={styles.authDialog__formCtn}>
              <TextField
                className={[
                  styles.auth__form__input,
                  styles.authDialog__formCtn__input,
                ].join(" ")}
                label="Phone Number or Email ID"
                variant="outlined"
                onChange={handleEmailChange}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "56px",
                  },
                }}
                inputProps={{
                  autoCapitalize: "none",
                }}
              />
              {errorMessage && (
                <Typography
                  variant="text-sm"
                  color="rose-600.main"
                  sx={{
                    marginLeft: "8px",
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
              <Button
                className={styles.authDialog__formCtn__button}
                disableElevation
                variant="contained"
                onClick={handleLoginSubmit}
                disabled={submitButtonDisabled}
              >
                {submitButtonDisabled ? (
                  <CircularProgress color="secondary" size={24} thickness={5} />
                ) : (
                  <Typography
                    variant="text-base-semibold"
                    component="div"
                    color="secondary"
                  >
                    {(authType === AUTH_TYPE.LOGIN && "Log In") ||
                      (authType === AUTH_TYPE.REGISTER && "Create Account")}
                  </Typography>
                )}
              </Button>
              <Typography className={styles.authDialog__ctaTextCtn}>
                {authType === AUTH_TYPE.LOGIN && (
                  <>
                    Don't have an account?{" "}
                    <span
                      className={styles.authDialog__ctaText}
                      onClick={handleCreateAccountCtaClick}
                    >
                      Create
                    </span>
                  </>
                )}
                {authType === AUTH_TYPE.REGISTER && (
                  <>
                    Already have an account?{" "}
                    <span
                      className={styles.authDialog__ctaText}
                      onClick={handleLoginCtaClick}
                    >
                      Login
                    </span>
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export { AUTH_TYPE, AUTH_CONTEXT };
export default Login;
