import React, { useState, useRef } from "react";
import { Box } from "@mui/material";

/**
 * props
 * - `otpLen` -> Specifies the length of the otp, and number of boxes to render
 * - `setParentOtpValue` -> A function that wraps a state setter & accepts the new otp value as a param 
 * This component will pass the new validated value to setParentOtpValue as a param
 * ex. `setParentOtpValue={(newValue) => setOtpStateValue(newValue)}`
 */
function OTPBoxes({
  otpLen = 4,
  setParentOtpValue,
  inputCtnClassName,
  inputCtnStyles,
  inputClassName,
  inputStyles,
}) {
  const [internalOtpVal, setInternalOtpVal] = useState(Array(otpLen).fill("")); // ["", "", "", ""]
  const inputCtnRef = useRef(null);

  function handleInternalOtpValChange(e, i) {
    const newDigitVal = e.target.value.trim(); // ex. = 4
    if (isNaN(newDigitVal)) return;

    if (newDigitVal.length > 0 && i < otpLen - 1) {
      inputCtnRef.current.childNodes[i + 1].focus();
    }

    if (newDigitVal.length >= 0 && newDigitVal.length <= 1) {
      const newOtpArrVal = internalOtpVal.map((otp) => otp); // ex. newOtpArrVal = ["1", "2", "3", "0"]
      newOtpArrVal[i] = newDigitVal;  // ex. ["1", "2", "3", "4"], i = 3

      setInternalOtpVal(newOtpArrVal);
      setParentOtpValue(newOtpArrVal.join("")); // ex. "1234", the new value, passed back to parent
    }
  }

  function handleBackspace(e, i) {
    if (i > 0 && e.key === "Backspace" && e.target.value.trim().length === 0) {
      e.preventDefault();
      inputCtnRef.current.childNodes[i - 1].focus();
    }
  }

  return (
    <Box
      className={inputCtnClassName}
      sx={{ ...inputCtnStyles }}
      ref={inputCtnRef}
    >
      {internalOtpVal.map((otpVal, i) => (
        <input
          key={i}
          className={inputClassName}
          style={{ ...inputStyles }}
          type="text"
          value={otpVal}
          onChange={(e) => handleInternalOtpValChange(e, i)}
          onFocus={(e) => e.currentTarget.select()}
          onKeyDown={(e) => handleBackspace(e, i)}
        />
      ))}
    </Box>
  );
}

export default OTPBoxes;
