import { useEffect, useState } from "react";

const useCountdown = (targetSeconds, start = false, cb = () => {}) => {
  const [countDown, setCountDown] = useState(targetSeconds);

  useEffect(() => {
    if (!start) return;

    const interval = setInterval(() => {
      setCountDown(countDown - 1);
    }, 1000);

    if (countDown == 0) {
      cb();
      clearInterval(interval);
      setCountDown(targetSeconds);
    }

    return () => clearInterval(interval);
  }, [countDown, start]);

  return getFormattedTime(countDown);
};

const getFormattedTime = (countDown) => {
  const minutes = Math.floor(countDown / 60);
  if (minutes < 10) {
    minutes = `0${minutes.toString()}`;
  }
  const seconds = Math.floor(countDown % 60);
  if (seconds < 10) {
    seconds = `0${seconds.toString()}`;
  }

  return `${minutes}:${seconds}`;
};

export { useCountdown };
