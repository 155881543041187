import FilterClass from "../filter.class";
import store from "../../../store.js";

const isEmptyFn = () => {
  return !store.getState().FilterReducer?.tripBudget;
};
const resetFn = () => {
  store.dispatch({
    type: "RESET_TRIP_BUDGET_FILTER",
  });
};
function getValueStr() {
  return this.value && this.value();
}

function isValidHighlightStateFn() {
  if (this.defaultValue === this.value()) return false;

  return true;
}

const defaultValue = 250000;

export const TripBudgetFilterObj = new FilterClass({
  id: "1",
  reduxKey: "tripBudget",
  name: "tripBudget",
  label: "Trip budget should be",
  prefix: "",
  resetFn,
  isEmptyFn,
  getValueStr,
  defaultValue,
  isValidHighlightStateFn,
});
