import { getMonthYear } from "../../utils/date";
import { collapseSpaces } from "../../utils/string";

export default class MetaTags {
  constructor({
    description = "",
    imageURL = "",
    permalink = "",
    keywords = [],
    title = "",
    sitename = "",
    noIndex = false,
  }) {
    this.title = title;
    this.description = description;
    this.imageURL = imageURL;
    // this.sitename = sitename;
    this.permalink = permalink;
    this.keywords = keywords;
    this.noIndex = noIndex;
  }
}

function generateTripMetaKeywords(trip) {
  const tripName = trip.name;
  const tripTypeName = trip.tripType.toLowerCase();
  const startLocName = trip.startingLocation;
  const tripHostName = trip.hostName;

  const tripNameKW = `trip to ${tripName}`;
  const tripDaysKW = `${trip.duration} day trip`;
  let tripTypeKW = `${tripTypeName} trip`;
  if (tripTypeName == "motorbike") {
    tripTypeKW += ",road trip";
  }
  if (tripTypeName != "tour" && tripTypeName != "spiritual") {
    tripTypeKW += ",adventure trip";
  }
  let tripFromKW = `trip from ${startLocName}`;
  let weekendTripKW = "";
  if (trip.isWeekendTrip) {
    weekendTripKW = `weekend trip`;
    tripFromKW = `weekend trip from ${startLocName}`;
  }
  let dayTripKW = "";
  if (trip.duration == 1) {
    dayTripKW = "day trip";
  }

  const budgetWK =
    trip.costPerPersonInNumber < 25000 ? "budget trip,low cost trip" : "";
  const itineraryWK = trip.itinerary?.length > 0 ? `${tripName} itinerary` : "";
  const womenOnlyKW = trip.womenOnly ? "women only trip,girls only trip" : "";
  const hostKW = `trip by ${tripHostName},contact ${tripHostName}`;
  const coupleKW = trip.coupleFriendly ? `couple friendly trip` : "";

  return [
    tripNameKW,
    hostKW,
    tripFromKW,
    womenOnlyKW,
    tripTypeKW,
    weekendTripKW,
    tripDaysKW,
    dayTripKW,
    budgetWK,
    itineraryWK,
    coupleKW,
  ];
}

function generateLocationKeywords(location) {
  const nameKW = `details about ${location.name}`;
  const weatherKW = `${location.name} weather`;
  const networkKW = `${location.name} internet and network`;
  const bestTimeKW = `best time to visit ${location.name}`;
  const tripKW = `upcoming trips to ${location.name}`;
  const regionKW = `${location.name} in ${location.region}`;
  return [nameKW, tripKW, weatherKW, bestTimeKW, networkKW, regionKW];
}

function generateHostKeywords(host) {
  // TODO
  let keywords = [];
}

function generateTripDescription(trip) {
  let specialTripKW = "";
  if (trip.womenOnly) {
    specialTripKW = "all girls";
  }
  if (trip.coupleFriendly) {
    specialTripKW = "couple friendly";
  }
  let weekendTripKW = "";
  if (trip.isWeekendTrip) {
    weekendTripKW = "weekend";
  }
  const tripMonth = getMonthYear(trip.startDate);
  const description = `Check out cost, transport, stay, meals, schedule, itinerary of ${specialTripKW} ${weekendTripKW} ${trip.tripType} trip to ${trip.name} in ${tripMonth} on Cohyk`;
  return description;
}

function generateHostDescription(host) {
  const description = `Upcoming trips, itineraries, images, experience, instagram, followers and host profile of host ${host.name} on Cohyk`;
  return description;
}

function generateLocationDescription(location) {
  const description = `Check out upcoming trips to ${location.name}, ${location.region} and location details like images, weather, network, local culture and geography for travel on Cohyk`;
  return description;
}

function generateTripPageTitle(trip) {
  let specialTripKW = "";
  if (trip.womenOnly) {
    specialTripKW = "for women";
  }
  if (trip.coupleFriendly) {
    specialTripKW = "for couple";
  }

  const preSpaceCollapse = `${trip.tripType} trip to ${trip.name} ${specialTripKW} with ${trip.hostName}`;
  const title = collapseSpaces(preSpaceCollapse);
  return title;
}

function generateHostPageTitle(host) {
  const title = `${host.name} - host profile, upcoming trips and itineraries`;
  return title;
}

function generateLocationPageTitle(location) {
  const title = `${location.name}, ${location.region} - upcoming trips and travel details about location`;
  return title;
}

export {
  generateHostKeywords,
  generateLocationKeywords,
  generateTripMetaKeywords,
  generateHostDescription,
  generateTripDescription,
  generateLocationDescription,
  generateHostPageTitle,
  generateTripPageTitle,
  generateLocationPageTitle,
};
