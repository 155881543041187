import axios from "axios";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import config from "../config";

const axiosInstance = axios.create({ baseURL: `${config.DOMAIN}api/v1/` });
const CancelToken = axios.CancelToken;

//handleGetApiCall takes four argument: (apiURL, params, callback func for success, callback for api fail)
/**
 *
 * @param {str} url URL Path of the API
 * @param {object} params Params object to pass with the API call
 * @param {function} handleSuccessFn Callback function for the successful API call, add return for handleGetApiCall to return data
 * @param {function} handleFailFn Optional. Callback function if the API call encounter failure. Even if the parsing error happens
 * @returns return from `handleSuccessFn`
 */
async function handleGetApiCall(
  url,
  params,
  handleSuccessFn,
  handleFailFn = () => {},
  headers = {},
  disableToast = false
) {
  let cancelledApi = false;
  if (params.cancelSource) {
    cancelledApi = true;
    params.cancelSource.current?.cancel();
    params.cancelSource.current = CancelToken.source();
  }

  const res = await axiosInstance
    .get(url, {
      ...params,
      cancelToken: params.cancelSource
        ? params.cancelSource.current?.token
        : undefined,
      withCredentials: true,
      headers: headers,
    })
    .then((res) => {
      return handleSuccessFn(res);
    })
    .catch((error) => {
      if (cancelledApi) {
        return;
      }

      if (!disableToast) {
        let errorMessage =
          error.response?.data?.detail?.message || error.message;

        if (error.response.status !== 401) {
          toast(errorMessage, {
            className: "Toastify__toast__background-danger",
            bodyClassName: "toast__body",
          });
        }
      }

      return handleFailFn(error);
    });

  return res;
}

handleGetApiCall.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  handleSuccessFn: PropTypes.func.isRequired,
};

async function handlePostApiCall({
  url,
  params,
  handleSuccessFn,
  handleFailFn = () => {},
  headers = {},
  disableToast = false,
}) {
  return await axiosInstance
    .post(url, params, {
      withCredentials: true,
      headers: headers,
    })
    .then((res) => {
      if (!disableToast && res.status != 200) {
        toast("Sorry,we are not able to process your request", {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
          bodyClassName: "toast__body",
        });
      }
      return handleSuccessFn(res);
    })
    .catch((error) => {
      if (!disableToast) {
        const errorMessage =
          error.response?.data?.detail?.message ||
          error.response?.data?.message ||
          error.message;
        toast(errorMessage, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
        });
      }

      handleFailFn(error);
    });
}
handlePostApiCall.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  handleSuccessFn: PropTypes.func.isRequired,
};

const uploadPhoto = async (
  e,
  params,
  upload_url,
  handleFailFn,
  disableToast,
  paramField = "photo"
) => {
  params[paramField] = e.target.files[0];

  let response = null;

  await handlePostApiCall({
    url: upload_url,
    params: params,
    handleSuccessFn: (res) => (response = res.data),
    headers: { "Content-Type": "multipart/form-data" },
    handleFailFn: handleFailFn,
    disableToast: disableToast,
  });

  return response;
};

export { handleGetApiCall, handlePostApiCall, axiosInstance, uploadPhoto };
