import getNumberInModernCountFormat from "../../utils/followers.count";
import Trip, { parseTripObject } from "../trip/trip";
import { getCurrentDate } from "../../utils/date";
import { handleGetApiCall } from "../../utils/api.call";
import config from "../../config";
import { parseReviewsAndRatings } from "../review/review";

const default_profile_photo_url =
  "https://cohyk.mo.cloudinary.net/op-v1/images/profile-default.jpg";
const default_cover_photo_url =
  "https://cohyk.mo.cloudinary.net/op-v1/images/host-cover-default.jpg?tx=q_100";

export function getDefaultHostObj() {
  const host = {
    id: 0,
    name: "",
    firstName: "",
    lastName: "",
    photoURL: default_profile_photo_url,
    searchPhotoURL: default_profile_photo_url,
    coverPhotoURL: default_cover_photo_url,
    about: "",
    hostOrganization: "",
    handle: "",
    instagramHandle: "",
    followersCount: 0,
    tripsCount: 0,
    imageUrls: [
      { id: 0, cdn_url: default_cover_photo_url, media_type: "image" },
    ],
    livesForEmoji: "",
    verifiedHost: false,
    upcomingTrips: [],
    urlPath: "",
    awards: [],
    followersCountAsInt: 0,
    team: [],
    hasAccount: false,

    whatsappFields: getDefaultWhatsappContactObj(),
    instagramMedia: [],

    tripsUrlPath: "",
    media: [],
    reviewsAndRatings: null,
  };

  return host;
}

export function parseHostObject(response) {
  const host = getDefaultHostObj();
  host.id = response?.id ?? host.id;
  host.name = [response?.first_name ?? "", response?.last_name ?? ""]
    .join(" ")
    .trim(" ");
  host.firstName = response?.first_name ?? host.firstName;
  host.lastName = response?.last_name ?? host.lastName;
  host.photoURL = response?.profile_photo?.cdn_url ?? default_profile_photo_url;
  host.coverPhotoURL =
    response?.background_photo?.cdn_url ?? default_cover_photo_url;
  host.searchPhotoURL =
    response?.profile_photo_url ?? default_profile_photo_url;
  host.about = response?.bio ?? host.about;
  host.hostOrganization = response?.organization ?? host.hostOrganization;
  host.handle = response?.handle ?? host.handle;
  host.instagramHandle = response?.instagram_handle ?? host.instagramHandle;
  host.followersCount = !isNaN(response?.followers_count)
    ? getNumberInModernCountFormat(response?.followers_count)
    : host.followersCount;
  host.tripsCount = response?.trips_count ?? host.tripsCount;
  host.imageUrls =
    response?.photos?.length > 0 ? response.photos : host.imageUrls;
  host.livesForEmoji = response?.lives_for_emoji ?? host.livesForEmoji;
  host.verifiedHost = response?.is_verified ?? host.verifiedHost;
  host.upcomingTrips =
    response?.upcoming_trips?.length > 0
      ? response.upcoming_trips.map((trip) => parseTripObject(trip))
      : host.upcomingTrips;
  host.urlPath = response?.url_path ?? host.urlPath;
  host.awards = response.awards?.length
    ? response.awards.map((award) => parseHostAwardObject(award))
    : host.awards;
  host.followersCountAsInt =
    response?.followers_count ?? host.followersCountAsInt;

  host.team = response.team
    ? response.team.map((member) => parseTeamMemberObject(member))
    : host.team;

  host.hasAccount = response?.has_account ?? host.hasAccount;

  host.whatsappFields = parseWhatsappContactObj(response);
  host.instagramMedia =
    response?.instagram_media?.map((media) => parseInstagramMediaObj(media)) ??
    host.instagramMedia;

  host.tripsUrlPath = response?.trips_url_path
    ? response?.trips_url_path.charAt(0) !== "/"
      ? `/${response.trips_url_path}`
      : response.trips_url_path
    : host.tripsUrlPath;
  host.media = response?.photos?.length > 0 ? response?.photos : host.media;
  host.reviewsAndRatings =
    parseReviewsAndRatings(response?.reviews_and_ratings) ??
    host.reviewsAndRatings;

  return host;
}

export function getHostFromId(hostId) {
  const handleSuccessFn = (res) => parseHostObject(res.data);
  return handleGetApiCall(`profile/${hostId}`, {}, handleSuccessFn, () => {});
}

function getDefaultHostAwardObj() {
  const award = {
    id: 0,
    name: "",
    category: "",
    photoUrl: "",
    dateReceived: getCurrentDate(),
    hostId: 0,
    link: "",
  };
  return award;
}

export function parseHostAwardObject(response) {
  const award = getDefaultHostAwardObj();
  award.id = response.id ?? award.id;
  award.awardId = response.award?.id ?? award.awardId;
  award.name = response.award?.name ?? award.name;
  award.category = response.category ?? award.category;
  award.photoUrl = response.award?.photo_url ?? award.photoUrl;
  award.dateReceived = response.date_received ?? award.dateReceived;
  award.hostId = response.host_id ?? award.hostId;
  award.link = response.link ?? award.link;

  return award;
}

export function getDefaultTeamMemberObject() {
  const team = {
    id: 0,
    name: "",
    title: "",
    bio: "",
    hostId: 0,
    photo: null,
  };
  return team;
}

export function parseTeamMemberObject(response) {
  const team = getDefaultTeamMemberObject();
  team.id = response?.id ?? team.id;
  team.name = response?.name ?? team.name;
  team.title = response?.title ?? team.title;
  team.bio = response?.bio ?? team.bio;
  team.hostId = response?.host_id ?? team.hostId;
  team.photo = response?.photo ?? team.photo;

  return team;
}

export function getDefaultWhatsappContactObj() {
  const whatsappContact = {
    contactOnWhatsapp: false,
    whatsappNumber: "",
    whatsappNumberCode: "",
    whatsappPhoneNumber: "",
  };

  return whatsappContact;
}

export function parseWhatsappContactObj(response) {
  const whatsappContact = getDefaultWhatsappContactObj();

  whatsappContact.contactOnWhatsapp =
    response.contact_on_whatsapp ?? whatsappContact.contactOnWhatsapp;
  whatsappContact.whatsappNumber =
    response.whatsapp_number ?? whatsappContact.whatsappNumber;
  whatsappContact.whatsappNumberCode =
    response.whatsapp_number_code ?? whatsappContact.whatsappNumberCode;
  whatsappContact.whatsappPhoneNumber =
    response.whatsapp_phone_number ?? whatsappContact.whatsappPhoneNumber;

  return whatsappContact;
}

export function getBaseGaWAMsgQueryString(hostHandle) {
  return `utm_source=wa&utm_medium=contact&utm_term=${hostHandle}`;
}

export function getAboutTripMsgTemplate({ location, date, link }) {
  return `Hello, I saw your trip to ${location} on ${date} on Cohyk - ${link}. I wanted to connect with you regarding this trip.`;
}

export function getAboutTripWAMsgTemplate({
  location,
  date,
  tripLink,
  hostHandle,
  tripId,
}) {
  const link = `${tripLink}?${getBaseGaWAMsgQueryString(
    hostHandle
  )}&utm_content=${tripId}`;
  const textMsg = getAboutTripMsgTemplate({ location, date, link });

  return encodeURIComponent(textMsg);
}

export function getEnquiryMsgTemplate({ link }) {
  return `Hello, I saw your trips and host profile on Cohyk - ${link}. I wanted to connect with you for an enquiry.`;
}

export function getEnquiryWAMsgTemplate({ profileLink, hostHandle }) {
  const link = `${profileLink}?${getBaseGaWAMsgQueryString(hostHandle)}`;
  const textMsg = getEnquiryMsgTemplate({ link });

  return encodeURIComponent(textMsg);
}

export function getHostWAContactLink(phoneNumber, textMsg) {
  return `https://wa.me/${phoneNumber}?text=${textMsg}`;
}

export const CHAT_CONNECT_CONTEXT = {
  HOST: "host",
  TRIP: "TRIP",
};

export function getHostEnquiryChatMsgTemplate() {
  return "Hello! I want to connect with you for an enquiry.";
}

export function getHostAboutTripChatMsgTemplate({ location, date, link }) {
  return `Hello! I have an enquiry regarding your trip to ${location} on ${date} - ${link}`;
}

export function getHostDefaultChatText({ context, hostDetails, tripDetails }) {
  let text = "";

  if (context === CHAT_CONNECT_CONTEXT.HOST) {
    text = getHostEnquiryChatMsgTemplate();
  } else if (context === CHAT_CONNECT_CONTEXT.TRIP) {
    const link = `${config.HOST}/${tripDetails.urlPath}`;
    const location = tripDetails.highlightLocation;
    const date = tripDetails.startDate_str;
    text = getHostAboutTripChatMsgTemplate({ location, date, link });
  }

  if (text) {
    return encodeURIComponent(text);
  }

  return text;
}

export function getDefaultInstagramMediaObj() {
  const mediaObj = {
    id: 0,
    hostId: 0,
    mediaId: 0,
    mediaType: "",
    mediaUrl: "",
    mediaThumbnailUrl: "",
    mediaTimestamp: "",
    mediaCaption: "",
    syncedAt: "",
  };

  return mediaObj;
}

export function parseInstagramMediaObj(response) {
  const mediaObj = getDefaultInstagramMediaObj();

  mediaObj.id = response?.id ?? mediaObj.id;
  mediaObj.hostId = response?.host_id ?? mediaObj.hostId;
  mediaObj.mediaId = response?.media_id ?? mediaObj.mediaId;
  mediaObj.mediaType = response?.media_type ?? mediaObj.mediaType;
  mediaObj.mediaUrl = response?.media_url ?? mediaObj.mediaUrl;
  mediaObj.mediaThumbnailUrl =
    response?.media_thumbnail_url ?? mediaObj.mediaThumbnailUrl;
  mediaObj.mediaTimestamp =
    response?.media_timestamp ?? mediaObj.mediaTimestamp;
  mediaObj.mediaCaption = response?.media_caption ?? mediaObj.mediaCaption;
  mediaObj.syncedAt = response?.synced_at ?? mediaObj.syncedAt;

  return mediaObj;
}

export default class Host {
  constructor(hostDetails = {}) {
    const default_profile_photo_url =
      "https://cohyk.mo.cloudinary.net/op-v1/images/profile-default.jpg";
    const default_cover_photo_url =
      "https://cohyk.mo.cloudinary.net/op-v1/images/host-cover-default.jpg?tx=q_100";

    this.id = hostDetails.id;
    this.name = [hostDetails.first_name, hostDetails.last_name]
      .join(" ")
      .trim(" ");
    this.firstName = hostDetails.first_name;
    this.lastName = hostDetails.last_name;
    this.photoURL = hostDetails.profile_photo?.cdn_url
      ? hostDetails.profile_photo?.cdn_url
      : default_profile_photo_url;
    this.searchPhotoURL = hostDetails.profile_photo_url;
    this.coverPhotoURL =
      hostDetails.background_photo?.cdn_url || default_cover_photo_url;
    this.about = hostDetails.bio;
    this.hostOrganization = hostDetails.organization;
    this.handle = hostDetails.handle;
    this.instagramHandle = hostDetails.instagram_handle;
    this.followersCount = getNumberInModernCountFormat(
      hostDetails.followers_count
    );
    this.tripsCount = hostDetails.trips_count;
    this.imageUrls =
      hostDetails.photos?.length > 0
        ? hostDetails.photos
        : [
            {
              id: 0,
              cdn_url: default_cover_photo_url,
            },
          ];
    this.livesForEmoji = hostDetails.lives_for_emoji;
    this.verifiedHost = hostDetails.is_verified;
    this.upcomingTrips = [];
    hostDetails.upcoming_trips?.map((val) => {
      this.upcomingTrips.push(new Trip(val));
    });
    this.urlPath = hostDetails.url_path;
    this.awards = hostDetails.awards?.length
      ? hostDetails.awards.map((award) => parseHostAwardObject(award))
      : [];
    this.tripsUrlPath = hostDetails?.trips_url_path
      ? hostDetails?.trips_url_path.charAt(0) !== "/"
        ? `/${hostDetails.trips_url_path}`
        : hostDetails.trips_url_path
      : "";
  }
}
