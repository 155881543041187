function parseCookies() {
  const cookies = document.cookie;
  if (!cookies) return {};

  let cookieObject = {};
  let index = 0;

  while (index < cookies.length) {
    let equalIdx = cookies.indexOf("=", index);

    // no more cookie pairs
    if (equalIdx === -1) break;

    let endIdx = cookies.indexOf(";", index);

    if (endIdx === -1) {
      endIdx = cookies.length;
    } else if (endIdx < equalIdx) {
      // backtrack to previous semicolon
      index = cookies.lastIndexOf(";", equalIdx - 1) + 1;
      continue;
    }

    var key = cookies.slice(index, equalIdx).trim();

    // put key,value in cookie object
    if (!cookieObject[key]) {
      let value = cookies.slice(equalIdx + 1, endIdx).trim();
      cookieObject[key] = value;
    }

    index = endIdx + 1;
  }

  return cookieObject;
}

function getCookie(cookie_str) {
  if (typeof cookie_str !== "string") return undefined;
  
  const cookieObj = parseCookies();
  return cookieObj[cookie_str];
}

/**
 *
 * @param {object} cookieObj An object, key value pairs
 * @returns A string with the format `key_1=value_1;key_2=value_2;...`
 */
function cookieHeaderString(cookieObj = {}) {
  let cookieStr = "";

  for (const [key, value] of Object.entries(cookieObj)) {
    cookieStr += `${key}=${value};`;
  }

  return cookieStr;
}

function getApiResponseCookies(res = {}, cookieArr = []) {
  let cookies = [...cookieArr];

  if (res.headers && res.headers["set-cookie"]) {
    cookies = [...cookies, ...res.headers["set-cookie"]];
  }

  return cookies;
}

export { parseCookies, getCookie, cookieHeaderString, getApiResponseCookies };
