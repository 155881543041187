
export default function getNumberInModernCountFormat(number) {
    const div1M = number / 1000000;
    if (div1M >= 1 && div1M < 1000) {
      return div1M.toFixed(1) + "M+";
    }
    
    const div1K = number / 1000;
    if (div1K >= 1 && div1K < 1000) {
      return div1K.toFixed(1) + "K+"
    }
  
    const div = number / 100;
    if (div >= 1 && div < 1000) {
      return parseInt(div) + "00+"
    }
  
    return number;
  }
  