const EVENTNAME = {
  SEARCH_TRIPS_CLICK: "search_trips_click",
  SIGNUP_HOST_CLICK: "signup_host_click",
  ADD_TRIP_CLICK: "add_trip_click",
  LOGIN_CLICK: "login_click",
  CONTACT_HOST_CLICK: "contact_host_click",
  FEEDBACK_CLICK: "feedback_click",
  ITINERARY_CLICK: "itinerary_click",
  TRIP_EXPECT_SEEMORE_CLICK: "expect_seemore_click",
  NAV_MENU_OPEN_CLICK: "nav_menu_open_click",
  SIGNUP_HOST_COMPLETE_CLICK: "signup_host_complete_click",
  LOGIN_COMPLETE_CLICK: "login_complete_click",
  FORGOT_PASSWORD_CLICK: "forgot_password_click",
  LOGIN_OTP_COMPLETE_CLICK: "login_otp_complete_click",
  SIGNUP_HOST_OTP_COMPLETE_CLICK: "signup_host_otp_complete_click",
  FORGOT_PASSWORD_OTP_COMPLETE_CLICK: "forgot_pwd_otp_complete_click",
  LOGIN_CANCEL_CLICK: "login_cancel_click",
  SIGNUP_HOST_CANCEL_CLICK: "signup_host_cancel_click",
  LOGIN_OTP_CANCEL_CLICK: "login_otp_cancel_click",
  SIGNUP_HOST_OTP_CANCEL_CLICK: "signup_host_otp_cancel_click",
  SEE_MORE_TRIPS_CLICK: "see_more_trips_click",
  SEE_OTHER_TRIPS_DEST_CLICK: "see_other_trips_dest_click",
  LOCATION_LEARN_MORE_CLICK: "location_learn_more_click",
  CREATE_DISCOUNT_CODE_CLICK: "create_discount_code_click",
  COPY_DISCOUNT_CODE_CLICK: "copy_discount_code_click",
  SHARE_TRIP_CLICK: "share_trip_click",
  SHARE_LOCATION_CLICK: "share_location_click",
  SHARE_HOST_CLICK: "share_host_click",
  SHARE_SEARCH_CLICK: "share_search_click",
  CONTACT_HOST_CLICK_CHAT: "contact_host_click_chat",
  CONTACT_HOST_CLICK_WA: "contact_host_click_wa",
  SEE_MORE_MEDIA_CLICK: "see_more_media_click",
  TRIP_DIALOG_OPEN: "trip_dialog_open",
  TEAM_MEMBER_CLICK: "team_member_click",
  OAUTH_LOGIN_CLICK_GOOGLE: "oauth_login_click_google",
  SIGNUP_COMPLETE: "signup_complete",
  CONTACT_HOST_CLICK_SIGNOUT: "contact_host_click_signout",
  BOOK_TRIP_CLICK: "book_trip_click",
  BOOK_TRIP_PAX: "book_trip_pax",
  BOOKING_REQUEST: "booking_request",
};

const SECTION = {
  NAVBAR: "navbar",
  FOOTER: "footer",
  TRIP_DIALOG: "trip_dialog",
  TRIP_PAGE: "trip_page",
  SEARCH_PAGE: "search_page",
  SEARCH_TRIPS: "search_trips",
  SEARCH_HERO: "search_hero",
  HOME_PAGE: "home_page",
  HOME_ADD_TRIP: "home_add_trip",
  LOCATION_PAGE: "location_page",
  HOST_PAGE: "host_page",
  SIGNUP_FORM: "signup_form",
  LOGIN_FORM: "login_form",
  FAQ_PAGE: "faq_page",
  TRIP_PAGE_DISCOUNT: "trip_page_discount",
  TRIP_DIALOG_DISCOUNT: "trip_dialog_discount",
  BOOKING_DIALOG: "booking_dialog",
};

export { EVENTNAME, SECTION };
