import store from "../../store";
export default class FilterClass {
  constructor({
    id,
    reduxKey,
    name,
    label,
    prefix = "",
    resetFn = () => {},
    isEmptyFn = () => {},
    getValueStr = () => {},
    defaultValue,
    isValidHighlightStateFn = () => {},
    defaultFilterValue = {},
  }) {
    this.id = id;
    this.reduxKey = reduxKey;
    this.name = name;
    this.value = function () {
      return store.getState().FilterReducer[this.reduxKey];
    };
    this.getValueStr = getValueStr;
    this.prefix = prefix;
    this.highlight = true;
    this.highlightString = function () {
      return this.prefix + " " + this.getValueStr();
    };
    this.dialogBoxLabel = label;
    this.reset = resetFn;
    this.isEmpty = isEmptyFn;
    this.defaultValue = defaultValue;
    this.isValidHighlightStateFn = isValidHighlightStateFn;
    this.defaultFilterValue = defaultFilterValue
  }
}
